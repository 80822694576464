import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { useTranslation } from 'react-i18next';
import PurchaserFields from './PurchaserFields';
import TaxpayerIdInput from './TaxpayerIdInput';

const PurchaserForm = ({ showPurchaserForm, initialValues, isExchange, showInvoiceToggle }) => {
  const { t } = useTranslation('payment');
  const { SHOW_PURCHASER_FORM_BILLING_TITLE: showBillingTitle } = useWhitelabelFeatures();

  return (
    <>
      {showInvoiceToggle && (
        <div className="checkout-header">
          <Text size="XL" weight="bold" className="checkout-header-title">
            {t('need_invoice')}
          </Text>
          <TaxpayerIdInput />
        </div>
      )}
      <div className="checkout-header">
        <Text size="XL" weight="bold" className="checkout-header-title">
          {showBillingTitle ? t('purchaser_info_billing') : t('purchaser_info')}
        </Text>
      </div>
      <PurchaserFields
        isExchange={isExchange}
        purchaser={initialValues}
        showPurchaserForm={showPurchaserForm}
      />
    </>
  );
};

PurchaserForm.propTypes = {
  initialValues: PropTypes.object,
  showPurchaserForm: PropTypes.bool,
  isExchange: PropTypes.bool,
  showInvoiceToggle: PropTypes.bool,
};

export default PurchaserForm;
