import React from 'react';
import PropTypes from 'prop-types';
import { feedbackClass } from '../utils/formValidations';

const CheckboxRenderer = ({ input, meta, id, onClick, children, label, disabled }) => (
  <label className="inline-checkbox">
    <div
      className={`checkboxes-check ${
        meta.error && (meta.touched || meta.dirty) ? 'has-error' : ''
      }`}
    >
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        type="checkbox"
        onClick={onClick}
        id={id}
        className={`checkbox ${feedbackClass(meta)}`}
        disabled={disabled}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id} />
    </div>
    {Boolean(label) && <span className="lodging-label">{label}</span>}
    {children}
  </label>
);

CheckboxRenderer.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

CheckboxRenderer.defaultProps = {
  children: null,
  id: '',
};

export default CheckboxRenderer;
