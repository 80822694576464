import store from '@/store';

export const getDoc2BrandMap = (target = 'passengers') => {
  const {
    env: { documentTypes },
  } = store.getState().whitelabelConfig;
  const targetDocumentTypes = documentTypes[target];
  const doc2BrandMap = Object.keys(targetDocumentTypes).reduce(
    (result, key) => ({
      ...result,
      [key]: targetDocumentTypes[key].brand,
    }),
    {},
  );
  return doc2BrandMap;
};

export const getBrand2ProcessorMap = () => {
  const {
    env: { documentTypes },
  } = store.getState().whitelabelConfig;
  const { passengers: passengersDocumentTypes, purchaser: purchaserDocumentTypes } = documentTypes;
  const allAvailableDocumentTypes = {
    ...passengersDocumentTypes,
    ...purchaserDocumentTypes,
  };
  const brand2ProcessorMap = Object.keys(allAvailableDocumentTypes).reduce(
    (result, key) => ({
      ...result,
      [allAvailableDocumentTypes[key].brand]: allAvailableDocumentTypes[key].processor,
    }),
    {},
  );
  return brand2ProcessorMap;
};

export const getIgnoredDocuments = () => {
  const {
    env: { documentTypes },
  } = store.getState().whitelabelConfig;
  const { ignoredDocumentsOnFirstPassenger = [] } = documentTypes;
  return ignoredDocumentsOnFirstPassenger;
};

export const getAvailableDocumentsByNationality = () => {
  const {
    env: { documentTypes },
  } = store.getState().whitelabelConfig;
  const { availableDocumentsByNationality = [] } = documentTypes;
  return availableDocumentsByNationality;
};

const documentTypeInstructionsi18nKeys = {
  CPF: 'passengers:instructions.cpf_or_cnpj_or_rg',
  CNPJ: 'passengers:instructions.cpf_or_cnpj_or_rg',
  RG: 'passengers:instructions.cpf_or_cnpj_or_rg',
};

export const getDocumentTypeInstructioni18NKey = (documentType = '') => {
  return documentTypeInstructionsi18nKeys[documentType] || '';
};
