import React from 'react';
import PropTypes from 'prop-types';
import { Text, Currency, Icon, Spacing, Gap } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import doterGreen from 'images/doters/greendoters.svg';
import doterSilver from 'images/doters/silverdoters.svg';
import doterGold from 'images/doters/golddoter.svg';
import './DotersRewardsBalance.scss';
import DotersRewardTooltip from '../../../ui/atoms/DotersRewardTooltip';

/**
  Component for displaying Doters rewards balance
  @param {Object} props - Component props
  @param {number} props.points - The current number of points
  @param {string} props.nextLevel - The name of the next level
  @param {number} props.nextLevelPoints - Points needed to reach the next level
  @param {number} props.pointsEarned - Points earned
  @returns {JSX.Element} - Doters rewards balance component
*/
const DotersRewardsBalance = ({ points, nextLevel, nextLevelPoints, pointsEarned }) => {
  const { t } = useTranslation('loyalty');

  const levelValues = {
    silver: {
      icon: doterSilver,
      level: 'points_from_the_level_silver',
    },
    gold: {
      icon: doterGold,
      level: 'points_from_the_level_gold',
    },
  };

  const level = levelValues[nextLevel]?.level;
  const levelIcon = levelValues[nextLevel]?.icon;

  return (
    <Spacing vertical size="XS">
      <div className="doters-points">
        <div className="doters-points-left">
          <Icon type={doterGreen} />
          <div className="doters-points-wrapper">
            <Text weight="bold" size="XL" mobileSize="M" color="white" textAlign="right">
              {t(pointsEarned ? 'generated' : 'will_generate')}
            </Text>
          </div>
        </div>
        <div className="doters-points-right">
          <Currency price={points} decimals={0} sign="" weight="bold" size="XL" color="loyalty" />
          <Text weight="bold" color="loyalty">
            {t('doters_points')}
          </Text>
        </div>
      </div>
      <Gap columnGap="M" alignItems="center">
        {nextLevel && (
          <DotersRewardTooltip levelIcon={levelIcon} level={nextLevel}>
            <Gap columnGap="XS" alignItems="center">
              <Text size="S" weight="semibold" color="loyalty">
                {t('you_are_to')}
                <Currency
                  price={nextLevelPoints}
                  decimals={0}
                  sign=""
                  weight="semibold"
                  size="S"
                  color="loyalty"
                />{' '}
                {t(level)}
              </Text>
              <Icon type={levelIcon} size="S" />
            </Gap>
          </DotersRewardTooltip>
        )}
        {pointsEarned && (
          <Text size="S" weight="semibold" color="loyalty">
            {t('it_may_take_few_reflected')}
          </Text>
        )}
      </Gap>
    </Spacing>
  );
};

DotersRewardsBalance.propTypes = {
  points: PropTypes.number.isRequired,
  nextLevel: PropTypes.oneOf(['gold, silver']).isRequired,
  nextLevelPoints: PropTypes.number,
  pointsEarned: PropTypes.bool,
};

DotersRewardsBalance.defaultProps = {
  nextLevelPoints: null,
  pointsEarned: false,
};

export default DotersRewardsBalance;
