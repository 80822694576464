import { camelizeKeys, decamelizeKeys } from 'humps';
import { getDistinctId } from 'user-analytics';
import { core as coreApi } from 'js-api-client';
import userFingerprint from '../userFingerprint';

/**
 * UserPreferencesService is a service for managing user preferences.
 */
const UserPreferencesService = () => {
  /**
   * Retrieves recommended trips based on the search ID.
   * @param {Object} options - The options for retrieving recommended trips.
   * @param {string} options.searchId - The search ID.
   * @returns {Promise} A promise that resolves with the recommended trips.
   * @throws {Error} If no distinct ID is available.
   */
  const getRecommendedTrips = ({ searchId }) => {
    return new Promise((resolve, reject) => {
      const userFingerprintId = userFingerprint.getFingerprint();
      if (!userFingerprintId) return reject(new Error('No fingerprint id'));

      coreApi
        .getRecommendedTrips({ searchId, userIdentifier: userFingerprintId })
        .then((response) => resolve(camelizeKeys(response)))
        .catch((error) => reject(error));
    });
  };

  /**
   * Creates trip price alerts.
   * @param {Object} payload - The payload for creating trip price alerts.
   * @param {number} payload.seen_price - Price showed to the user previously.
   * @param {string} payload.origin - origin slug
   * @param {string} payload.destination - destination slug
   * @param {string} payload.departure_date - date trip departure
   * @param {string} payload.email - require email user
   * @param {string} payload.phone -  phone number user
   * @param {string} payload.user_fingerprint - id fingerprint
   * @param {string} payload.tracker_id - distinct id mixpanel
   * @returns {Promise} A promise that resolves with the created trip price alerts.
   * @throws {Error} If no user fingerprint or distinct ID is available.
   */
  const createTripPriceAlerts = (payload) => {
    return new Promise((resolve, reject) => {
      const userFingerprintId = userFingerprint.getFingerprint();
      const distinctId = getDistinctId();
      if (!distinctId) return reject(new Error(`No distinct id`));
      if (userFingerprintId) payload.userFingerprint = userFingerprintId;
      coreApi
        .createTripPriceAlerts({
          tracker_id: distinctId,
          ...decamelizeKeys(payload),
        })
        .then((response) => resolve(camelizeKeys(response)))
        .catch((error) => reject(error));
    });
  };

  return {
    getRecommendedTrips,
    createTripPriceAlerts,
  };
};

const userPreferencesService = UserPreferencesService();
export default userPreferencesService;
