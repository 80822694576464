/* eslint-disable no-case-declarations */
import * as types from 'constants/ActionTypes';
import { camelizeKeys } from 'humps';

const initialState = {
  isLoading: false,
  ticket: {
    openTicket: '',
    origin: '',
    destination: '',
    operationDate: '',
    time: '',
    email: '',
    firstName: '',
    lastName: '',
    secondLastName: '',
    ticketPrice: '',
    operationNumber: '',
    nit: '',
  },
  trip: {
    originDisplay: '',
    originSlug: '',
    destinationDisplay: '',
    destinationSlug: '',
    date: '',
  },
};

export default function exchange(state = initialState, action) {
  switch (action.type) {
    case types.EXCHANGE_TYPEAHEAD_CHANGE:
      return {
        ...state,
        trip: {
          ...state.trip,
          [`${action.name}Display`]: action.display,
          [`${action.name}Slug`]: action.slug,
        },
      };
    case types.EXCHANGE_TYPEAHEAD_CLEAR:
      const origin = action.origin ? { originDisplay: '', originSlug: '' } : {};
      const destination = action.destination ? { destinationDisplay: '', destinationSlug: '' } : {};

      return {
        ...state,
        trip: {
          ...state.trip,
          ...origin,
          ...destination,
        },
      };
    case types.EXCHANGE_DATEPICKER_CHANGE:
      return {
        ...state,
        trip: {
          ...state.trip,
          date: action.date,
        },
      };
    case types.EXCHANGE_SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case types.EXCHANGE_RECEIVE:
      const { data } = action;
      const { tickets } = data;

      return {
        ...state,
        ticket: {
          ...state.ticket,
          openTicket: data.open_ticket,
          origin: data.origin,
          destination: data.destination,
          operationDate: data.operation_date,
          time: data.time,
          email: data.email,
          ticketPrice: tickets[0].ticket_price,
          operationNumber: tickets[0].transporter_key,
          nit: action.nit,
        },
        operationNumbers: camelizeKeys(tickets)?.map((passenger) => passenger.transporterKey),
        passengers: camelizeKeys(tickets),
        trip: {
          ...state.trip,
          originDisplay: data.origin,
          originSlug: data.origin_slug,
          destinationDisplay: data.destination,
          destinationSlug: data.destination_slug,
          date: data.search_date,
        },
      };

    case types.EXCHANGE_RESET:
      return initialState;

    case types.ERROR_TRIP_EXCHANGE:
      return {
        ...state,
        errorTrip: action.errorTrip,
      };

    default:
      return state;
  }
}
