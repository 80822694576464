import React from 'react';
import { Badge, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * It shows the purchase token.
 * @param {*} props
 * @param {string} props.token - Purchase token
 * @returns - PurchaseToken component.
 */
const PurchaseToken = ({ token, isRound, displayTransporterKey }) => {
  const { t } = useTranslation('purchase');

  if (!token) return null;

  return (
    <Spacing size="XS" alignItems="center">
      <Text size="S" color="grayLight" weight="semibold" whiteSpace="nowrap">
        {displayTransporterKey
          ? `${t('label.transporter_key', { context: isRound ? 'round' : '' })}: `
          : `${t('label.purchase_token')}: `}
      </Text>
      <Badge roundCorners type="gray">
        <Text size="S" color="grayLight" weight="semibold" elementType="span" letterSpacing="1">
          {token}
        </Text>
      </Badge>
    </Spacing>
  );
};

PurchaseToken.propTypes = {
  token: PropTypes.bool.isRequired,
  isRound: PropTypes.bool,
  displayTransporterKey: PropTypes.bool,
};

PurchaseToken.defaultProps = {
  isRound: false,
  displayTransporterKey: false,
};

export default PurchaseToken;
