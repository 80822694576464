import React from 'react';
import PropTypes from 'prop-types';
import mastercard from 'images/payment/mastercard.svg';
import visa from 'images/payment/visa.svg';
import americanexpress from 'images/payment/americanexpress.svg';
import paymethodcheck from 'images/payment/accepted.svg';
import 'styles/components/purchase/InstallmentsSelectedPlan';
import { useTranslation } from 'react-i18next';
import { getCurrencySuffix } from 'utils/currency';

const propTypes = {
  selectedInstallmentsPlan: PropTypes.shape({
    card: PropTypes.string.isRequired,
    months: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
  }).isRequired,
  onChangeClick: PropTypes.func.isRequired,
};

const InstallmentsSelectedPlan = ({ selectedInstallmentsPlan, onChangeClick }) => {
  const { t } = useTranslation('purchase');
  const { card, months, total, monthlyPayment } = selectedInstallmentsPlan;

  return (
    <div className="installments-selected-plan">
      <p className="installments-selected-plan-title">{t('label.payment_method')}:</p>

      <div className="installments-selected-plan-row">
        <div className="installments-selected-plan-item">
          <img src={paymethodcheck} />

          {card === 'visaMc' ? (
            <div>
              <img src={visa} />
              <img src={mastercard} />
            </div>
          ) : (
            <img src={americanexpress} />
          )}
        </div>

        <div className="installments-selected-plan-item">
          <p>
            {t('text.payments', {
              months,
              monthlyPayment,
              currency_suffix: getCurrencySuffix(),
            })}
          </p>
        </div>
      </div>

      <div className="installments-selected-plan-row bottom">
        <p className="installments-total">
          total {total} {getCurrencySuffix()}
        </p>
        <a className="installments-selected-plan-change" onClick={onChangeClick}>
          {t('link.change_number_of_terms')}
        </a>
      </div>
    </div>
  );
};

InstallmentsSelectedPlan.propTypes = propTypes;

export default InstallmentsSelectedPlan;
