import { connect } from 'react-redux';
import { EXCHANGE_RESET } from 'constants/ActionTypes';
import ExchangeSidebar from './ExchangeSidebar';

const mapStateToProps = ({ exchange }) => {
  const { passengers } = exchange;
  const { origin, destination, time, dueDate, openTicket } = exchange.ticket;
  return {
    origin,
    destination,
    time,
    dueDate,
    openTicket,
    passengers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetExchange: () => dispatch({ type: EXCHANGE_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeSidebar);
