const getSeats = (layout, tripSlug) => {
  const seats = {};
  const categories = [];
  let seatIndex = 0;

  layout
    .flat(2)
    .filter((item) => item.number)
    .forEach((item) => {
      if (!item?.seatLevel) {
        // If seatLevel is undefined or null, just use the seat number as the key
        seatIndex += 1;
        seats[item.number] = { ...item, tripSlug, index: seatIndex };
      } else if (item.seatLevel === '1') {
        // Keep the seat number as is for level 1
        seatIndex += 1;
        seats[item.number] = { ...item, tripSlug, index: seatIndex };
      } else if (item.seatLevel === '2') {
        // For level 2, start numbering after the last index of level 1 seats
        seatIndex += 1;
        const seatKey = seatIndex; // Incremented to continue after level 1
        seats[seatKey] = { ...item, tripSlug, index: seatIndex };
      }

      // Collect categories as before
      if (categories.indexOf(item.category) === -1) categories.push(item.category);
    });

  seats.categories = categories;
  return seats;
};

export default getSeats;
