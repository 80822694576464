import store from '@/store';
import { camelizeKeys } from 'humps';
import { isPetFriendlySeat } from './purchase/seats';
import growthBook from '../services/growthBook';

const LS_DEPARTURE_SEATS_EXPIRATION = 60 * 60 * 1000; // 1 hour
const LS_DEPARTURE_SEATS = 'reservamos-selectedSeats';

/**
 * Get the pricing for a trip.
 * @param {Object} params - The parameters object.
 * @param {Object} params.trip - The trip object.
 * @param {string} params.way - The way of the trip.
 * @param {boolean} params.isRound - Whether the trip is round or not.
 * @returns {number} - The pricing for the trip.
 */
export const getTripPricing = ({ trip, way, isRound }) => {
  if (isRound) {
    return way === 'departure' ? trip?.departureRoundTripPricing : trip?.roundTripPricing;
  }
  return trip?.pricing;
};

/**
 * Saves the selected seats on local storage.
 * @param {Object} params - The params object containing the id and seats.
 * @param {string} params.id - The id of the trip.
 * @param {array} params.seats - The selected seats.
 */
export const saveSeatsOnLS = ({ id, seats }) => {
  const newExpiration = Date.now() + LS_DEPARTURE_SEATS_EXPIRATION;
  const lsSeats = { id, seats, expiresAt: newExpiration };
  localStorage.setItem(LS_DEPARTURE_SEATS, JSON.stringify(lsSeats));
};

/**
 * Retrieves the selected seats from local storage.
 * @param {string} id - The id of the trip.
 * @returns {array} - The selected seats.
 */
export const getSeatsFromLS = ({ id }) => {
  try {
    const lsSeats = localStorage.getItem(LS_DEPARTURE_SEATS);
    if (!lsSeats) return null;

    const { id: lsId, seats, expiresAt } = JSON.parse(lsSeats);

    const isExpired = Date.now() > expiresAt;
    if (isExpired || lsId !== id) {
      localStorage.removeItem(LS_DEPARTURE_SEATS);
      return null;
    }
    return seats || [];
  } catch (error) {
    return [];
  }
};

/**
 * Get the selected seats from the seats state or local storage.
 * @param {Object} params - The parameters object.
 * @param {Object} params.seatsState - The seats state object.
 * @param {string} params.fromId - The id of the trip.
 * @param {boolean} params.isFromOpen - The open ticket status of the trip.
 * @returns {array} - The selected seats.
 */
export const getFromSelectedSeats = ({ seatsState, fromId, isFromOpen }) => {
  if (isFromOpen) return [];
  let fromSelectedSeats = seatsState[fromId]?.selectedSeats?.filter(
    (seat) => !seat.isPickedAsAdjacent,
  );
  if (!fromSelectedSeats) {
    fromSelectedSeats = getSeatsFromLS({ id: fromId });
  }
  return fromSelectedSeats;
};

/**
 * Get the props needed when a trip is passed as a prop
 * @param {Object} params - The parameters object
 * @param {String} params.way - The way of the trip
 * @param {Object} params.trip - The trip object
 * @param {Object} params.fromTrip - The fromTrip object
 * @returns
 */
export const getSeatSelectorPropsFromTrip = ({ way = 'departure', trip, fromTrip }) => {
  const seatsBus = store.getState().seatsBus.toJS();
  const {
    layout: seatsLayout,
    seats,
    busCategories,
    selectedSeats,
    error,
  } = seatsBus[trip.id] || {};
  const { allowsSeatSelection, openTicket, isPetFriendly } = trip;
  // const fromAllowsSeatSelection = fromTrip?.allowsSeatSelection && !fromTrip?.openTicket;
  const tripAllowsSeatSelection = allowsSeatSelection && !openTicket;
  /*
   * Fragments are create based on the way trip, both are created because the component needed like that.
   * But only one of them will have a value, the other will be an empty array.
   */
  let departureFragments = [];
  let returnFragments = [];
  let minPassengers = 0;

  const tripPricing = getTripPricing({ trip, way, isRound: Boolean(fromTrip) });
  const passengerTypes = trip.passengerTypes || [];

  if (way === 'departure') {
    departureFragments = [trip];
  }

  let departureSeats;
  if (way === 'return') {
    /**
     *  Empty value to just fetch the return trip
     */
    departureFragments = [];
    returnFragments = [trip];
    departureSeats = getFromSelectedSeats({
      seatsState: seatsBus,
      fromId: fromTrip.id,
      isFromOpen: fromTrip.openTicket,
    });
    minPassengers = fromTrip.openTicket ? 0 : departureSeats?.length || 0;
  }
  return {
    tripId: trip.id,
    hasSeats: Boolean(seatsLayout?.length && seats && Object.keys(seats).length),
    departureFragments,
    returnFragments,
    hasDetails: false,
    isFetchingDetails: false,
    passengers: [],
    isExchange: false,
    busCategories: busCategories || [],
    isLockingTickets: false,
    fetchingDetails: false,
    departureSelectedSeats: departureSeats,
    selectedSeats: selectedSeats || [],
    layout: seatsLayout || [],
    lockedSeats: [],
    isPetFriendlyTrip: isPetFriendly,
    seats,
    diagramType: 'bus',
    isOpenTicket: openTicket,
    isLoading: false,
    minPassengers,
    allowsSeatSelection: tripAllowsSeatSelection,
    canResetPassengers: false,
    canGoBack: false,
    isPurchaseView: false,
    tripPricing,
    trip,
    fetchingDetailsError: error,
    passengerTypes,
  };
};

/**
 * Get the props needed when a purchase is passed as a prop.
 * @param {Object} params - The parameters object.
 * @param {Object} params.purchase - The purchase object.
 * @param {string} params.way - The way of the purchase.
 * @returns {Object} - The props needed for the purchase.
 */
export const getSeatSelectorPropsFromPurchase = ({ purchase, way = 'departure' }) => {
  const {
    departs,
    returns,
    hasDetails,
    isFetchingDetails,
    passengers,
    isExchange,
    busCategories,
    isUpdating,
    refreshingBus,
  } = purchase;

  const { fragments: departureFragments, diagramType } = departs || {};
  const { fragments: returnFragments = [] } = returns || {};

  const purchaseDepartureTrip = departureFragments[0];
  const { tickets } = purchaseDepartureTrip;
  const isOpenDepartureTicket = purchaseDepartureTrip.openTicket;
  const lockedDepartureSeats = tickets?.map((ticket) => ticket.seat);

  const purchaseReturnTrip = returnFragments[0] || {};
  const { tickets: returnTickets } = purchaseReturnTrip || [];
  const isOpenReturnTicket = purchaseReturnTrip.openTicket;
  const lockedReturnSeats = returnTickets?.map((ticket) => ticket.seat);

  const lockedSeats = way === 'departure' ? lockedDepartureSeats : lockedReturnSeats;

  const isOpenTicket = way === 'departure' ? isOpenDepartureTicket : isOpenReturnTicket;

  const isLockingTickets =
    way === 'departure' ? departs?.isLockingTickets : returns?.isLockingTickets;
  const fetchingDetails = way === 'departure' ? departs?.fetchingDetails : returns?.fetchingDetails;
  const selectedSeats = way === 'departure' ? departs?.selectedSeats : returns?.selectedSeats;
  const layout = way === 'departure' ? departs?.layout : returns?.layout;

  const isPetFriendlyTrip =
    way === 'departure' ? purchaseDepartureTrip.isPetFriendly : purchaseReturnTrip.isPetFriendly;
  const seats = way === 'departure' ? departs?.seats : returns?.seats;

  let minPassengers = isExchange ? passengers.length : 0;
  if (way === 'return') {
    minPassengers = purchaseDepartureTrip.openTicket ? 0 : passengers.length;
  }

  const departureCanSelectSeats =
    !purchaseDepartureTrip.openTicket && purchaseDepartureTrip.allowsSeatSelection;

  const returnCanSelectSeats = purchaseReturnTrip.allowsSeatSelection && !isOpenTicket;

  const allowsSeatSelection = way === 'departure' ? departureCanSelectSeats : returnCanSelectSeats;
  const canGoBack = way === 'return' && departureCanSelectSeats;

  const canResetPassengers = departureCanSelectSeats || returnCanSelectSeats;

  const tripPricing = getTripPricing({
    trip: way === 'departure' ? purchaseDepartureTrip : purchaseReturnTrip,
    way,
    isRound: Boolean(purchaseReturnTrip),
  });

  const hasSeats = Boolean(layout?.length && seats && Object.keys(seats).length);
  const isLoading = !allowsSeatSelection || isLockingTickets || !hasSeats || isUpdating;

  const [firstFloorReclination, secondFloorReclination] =
    (way === 'departure'
      ? purchaseDepartureTrip.seatsRecline
      : purchaseReturnTrip.seatsRecline
    )?.split('/') || [];

  return {
    tripId: way === 'departure' ? purchaseDepartureTrip.id : purchaseReturnTrip.id,
    hasSeats,
    purchase,
    departureFragments,
    returnFragments,
    hasDetails,
    isFetchingDetails,
    passengers,
    isExchange,
    busCategories,
    isLockingTickets,
    fetchingDetails,
    selectedSeats,
    layout,
    lockedSeats,
    isPetFriendlyTrip,
    seats,
    diagramType,
    isOpenTicket,
    isLoading,
    minPassengers,
    canGoBack,
    allowsSeatSelection,
    canResetPassengers,
    isPurchaseView: true,
    tripPricing,
    firstFloorReclination,
    secondFloorReclination: secondFloorReclination || firstFloorReclination,
    refreshingBus,
  };
};

/**
 * Checks if a reservation has selected seats or if the seat selection is not allowed.
 * @param {Object} reservation - The reservation object.
 * @returns {boolean} - Returns true if the reservation has selected seats or seat selection is not allowed.
 */
export const reservationHasSelectedSeats = (reservation) => {
  const seats = reservation.get('selectedSeats');
  const hasSeats = Boolean(seats && seats.size > 0);
  const allowsSeatSelection =
    reservation.get('transportType') === 'bus' &&
    reservation.getIn(['fragments', 0, 'line', 'allowsSeatSelection']);

  return !allowsSeatSelection || hasSeats;
};

/**
 * Avoid to select petFriendly as first selected seat
 * If first is pet friendly and there are non pet friendly seats then swap
 * @param {array} seats
 * @returns {array}
 */
export const preventSelectPetFriendly = (seats) => {
  if (seats && seats.length > 1) {
    if (seats.some(({ category }) => isPetFriendlySeat(category))) {
      const nonSpecialSeatPosition = seats.findIndex(
        ({ category }) => !isPetFriendlySeat(category),
      );
      if (nonSpecialSeatPosition > 0) {
        const deleted = seats.splice(nonSpecialSeatPosition, 1);
        seats = [...deleted, ...seats];
      }
    }
  }
  return seats;
};

/**
 * Categorizes the seats into occupied and free.
 * @param {Object} params - function params.
 * @param {Object} params.seats - The seats object.
 * @param {Array} params.selectedSeats - The selected seats.
 * @returns {Object} seatsCount - An object containing the categorized seats.
 * @returns {Number} seatsCount.occupied - The number of occupied seats.
 * @returns {Number} seatsCount.free - The number of free seats.
 * @returns {Number} seatsCount.selected - The number of selected seats.
 */
export const getSeatsCount = ({ seats = {}, selectedSeats = [] }) => {
  const seatsCounted = Object.keys(seats).reduce(
    (result, seatNumber) => {
      const seat = seats[seatNumber];
      // It is important to use the strict equality operator here to avoid counting not valid values on seats.
      if (seat.occupied === true) result.occupied += 1;
      if (seat.occupied === false) result.available += 1;
      return result;
    },
    { occupied: 0, available: 0 },
  );

  return {
    ...seatsCounted,
    selected: selectedSeats.length,
  };
};

/**
 * Updates a seat in the selected seats array.
 * @param {Object} options - The options object.
 * @param {Array} options.selectedSeats - The array of selected seats.
 * @param {Object} options.seat - The seat object to update.
 * @returns {Array} - The updated array of selected seats.
 */
export const getUpdatedSeats = ({ selectedSeats, seat }) => {
  const selectedSeatsCopy = [...selectedSeats];
  const index = selectedSeatsCopy.findIndex((selectedSeat) => selectedSeat.number === seat.number);
  selectedSeatsCopy[index] = seat;
  return selectedSeatsCopy;
};

/**
 * Retrieves the purchase payload for selected seats.
 * @param {Object} options - The options object.
 * @param {string} options.departureId - The departure ID.
 * @param {string} options.returnId - The return ID.
 * @returns {Object|null} - The purchase payload for selected seats, or null if no seats are selected.
 */
export const getSeatsPurchasePayload = ({ departureId, returnId }) => {
  const seatsBus = store.getState().seatsBus.toJS();
  const departureSeats =
    seatsBus[departureId]?.selectedSeats || getSeatsFromLS({ id: departureId }) || [];
  const returnSeats = seatsBus[returnId]?.selectedSeats || [];

  if (!departureSeats?.length && !returnSeats?.length) return null;

  return camelizeKeys({
    preSelectedSeats: {
      departure: departureSeats,
      return: returnSeats,
    },
  });
};

// the selected seats are filter to only take the ones have a seat number.
/**
 * Retrieves the seats for a specific way of a purchase.
 * @param {Object} rawPurchase - The raw purchase object.
 * @param {string} way - The way (e.g., 'departs', 'returns').
 * @returns {Array} - The array of seats for the specified way.
 */
export const getTicketsSeats = (rawPurchase, way) => {
  const ticketsFromFragments =
    rawPurchase[way]?.fragments
      ?.reduce((acc, fragment) => {
        const fragmentTickets = fragment.tickets || [];
        return [...acc, ...fragmentTickets];
      }, [])
      ?.filter((ticket) => /\d/.test(ticket.seat))
      .map((ticket) => {
        return {
          number: ticket.seat,
          category: ticket.category,
          seatLevel: ticket.seatLevel,
          seatFloor: ticket.seatFloor,
          adjacentSeats: {
            numbers: [ticket.isAdjacentSeat ? ticket.parentAdjacentSeat : ticket.adjacentSeat],
            // TODO For price we need to search on the other tickets
            // price
          },
          ...(ticket.adjacentSeat && { isAdjacentPicked: true }),
          ...(ticket.isAdjacentSeat && { isPickedAsAdjacent: true }),
          tripSlug: ticket.tripSlug || '',
        };
      }) ?? [];
  return ticketsFromFragments;
};

/**
 * Get the selected seats for a purchase.
 * @param {Object} options - The options object.
 * @param {Object} options.purchaseState - The purchase state.
 * @returns {Object} - The selected seats for departure and return.
 */
export const getPurchaseSelectedSeats = ({ purchaseData, currentSelectedSeats }) => {
  const { isExchange } = purchaseData;
  const departureWaySeats = getTicketsSeats(purchaseData, 'departs') || [];
  const returnWaySeats = getTicketsSeats(purchaseData, 'returns') || [];

  /**
   * Merges seat data from current and new selections to retain existing seat details.
   * @param {Array} existingSeats - The array of seats currently selected with detailed information.
   * @param {Array} newSeats - The array of new seats to be merged, potentially lacking full details.
   * @returns {Array} - An array of seats with merged information, combining existing and new seat data.
   */
  const mergeSeatData = (existingSeats, newSeats) => {
    return newSeats.map((newSeat) => {
      const matchedSeat = existingSeats.find((seat) => seat.number === newSeat.number);
      return matchedSeat ? { ...matchedSeat, ...newSeat } : newSeat;
    });
  };

  const selectedDepartureSeats = mergeSeatData(currentSelectedSeats.depart, departureWaySeats);
  const selectedReturnSeats = mergeSeatData(currentSelectedSeats.return, returnWaySeats);

  const preSelectedSeats = purchaseData.preSelectedSeats || {};

  return {
    departure: selectedDepartureSeats.length
      ? selectedDepartureSeats
      : (!isExchange && preSelectedSeats.departure) || [],
    return: selectedReturnSeats.length
      ? selectedReturnSeats
      : (!isExchange && preSelectedSeats.return) || [],
  };
};

/**
 * Checks if the seats are shown in results.
 * @returns {Boolean}
 */
export const isSeatsOnResultActivated = () => {
  const { features } = store.getState().whitelabelConfig;
  const resultsSeats = growthBook.getGrowthBookFeature('results_seats');
  const resultsSimple = growthBook.getGrowthBookFeature('enable_categories');
  // TO DO: remove the growthBook experiment
  return resultsSeats && features.SEATS_ON_RESULTS && (resultsSimple !== 'control' ?? true);
};

/**
 * Checks if the category  is enabled .
 * @returns {Boolean}
 */
export const isPreviewCategoriesEnabled = () => {
  const resultsSimple = growthBook.getGrowthBookFeature('enable_categories');
  // TO DO: remove the growthBook experiment
  if (!resultsSimple) return true;
  return Boolean(resultsSimple === 'categories');
};
