import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { change, Field } from 'redux-form';
import { selectRenderer } from 'utils/formRenderers';
import { useDispatch } from 'react-redux';

function MinorTravelModeField({ age, name }) {
  const dispatch = useDispatch();
  const [isOverTwelve, setIsOverTwelve] = useState(false);
  const { t } = useTranslation('passengers');

  useEffect(() => {
    setIsOverTwelve(age >= 12);

    if (age >= 18) {
      dispatch(change('passengers', name, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [age, name]);

  if (age >= 18) return null;

  // TODO: GET THIS DATA FROM THE BACKEND
  const options = [
    // ? Viaje acompa;ado - Travel accompanied - Voyage accompagné  - Reizen met notariële toestemming - Reis begeleid
    { label: t('label.accompaniedTravel'), value: 'accompaniedTravel' },
    // ? Viaje con permiso notarial - Travel with notarial permission - Voyage avec autorisation notariale - Reizen met notariële toestemming
    ...(isOverTwelve
      ? [{ label: t('label.notarialPermissionTravel'), value: 'notarialPermissionTravel' }]
      : []),
  ];

  return (
    <Field
      name={name}
      id={name}
      component={selectRenderer}
      label={t('label.minor_travel_mode')}
      placeholder={t('placeholder.minor_travel_mode')} // ?  Mode de voyage (Mineur) - Reismodus (Minderjarige)
      options={options}
    />
  );
}

MinorTravelModeField.propTypes = {
  age: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default MinorTravelModeField;
