import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DotersPointsEarned from './DotersPointsEarned';
import { isServiceAllowed } from '../../../utils/loyalty';
import useWhitelabelFeatures from '../../../hooks/whitelabel/useWhitelabelFeatures';

/**
 * Container of the Doters Points Earned components
 * @param {Object} props - Component props
 * @param {Object} props.showLink - Link to show in the component
 * @returns {JSX.Element} Component
 */
function DotersPointsEarnedContainer({ showLink, useBox }) {
  const features = useWhitelabelFeatures();
  const purchase = useSelector((state) => state.purchase);
  const payment = useSelector((state) => state.payment);
  const siemprePlus = useSelector((state) => state.siemprePlus);

  const {
    status: purchaseStatus,
    walletAccrualPoints,
    departs,
    returns,
    costapassToken,
    walletType,
    walletOriginalType,
    isUpdating,
    total,
    isExchange,
    selectedPaymentOption,
  } = purchase.toJS();
  const { status: paymentStatus } = payment.toJS();
  const siemprePlusJS = siemprePlus.toJS();

  if (
    !isUpdating &&
    ((walletOriginalType && walletOriginalType !== 'doters') ||
      walletType !== 'doters' ||
      !isServiceAllowed({ departs, returns, allowedServices: features.DOTERS_ALLOWED_SERVICES }) ||
      (total === 0 && isExchange) ||
      !features.DOTERS_ENABLED ||
      !siemprePlusJS.user ||
      costapassToken)
  ) {
    return null;
  }

  const haveEarned = paymentStatus === 'charged' || purchaseStatus === 'completed';

  const levelPoints = {
    gold: { name: 'gold', points: 25000 },
    silver: { name: 'silver', points: 12500 },
  };

  let nextLevel = {};
  let normalizeLevel = '';
  let pointsForNextLevel = 0;

  if (siemprePlusJS.user) {
    const {
      user: { points, level },
    } = siemprePlusJS;
    nextLevel = levelPoints.silver;
    normalizeLevel = level.toLowerCase();
    if (normalizeLevel === 'silver') nextLevel = levelPoints.gold;
    pointsForNextLevel = nextLevel.points - points;
  }

  const canGenerate = selectedPaymentOption !== 'paycash';
  const showNextLevel = features.SHOW_DOTERS_NEXT_LEVEL && pointsForNextLevel > 0 && nextLevel.name;
  return (
    <DotersPointsEarned
      useBox={useBox}
      canGenerate={canGenerate}
      showLink={showLink}
      points={walletAccrualPoints}
      pointsEarned={haveEarned}
      isLoading={isUpdating}
      nextLevel={showNextLevel}
      currentLevel={normalizeLevel.toLowerCase()}
      pointsForNextLevel={pointsForNextLevel}
    />
  );
}

DotersPointsEarnedContainer.propTypes = {
  useBox: PropTypes.bool,
  showLink: PropTypes.bool,
};

DotersPointsEarnedContainer.defaultProps = {
  useBox: false,
  showLink: false,
};

export default DotersPointsEarnedContainer;
