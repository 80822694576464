import moment from 'moment';
import store from '@/store';
import { encodePassengers } from './Reserbus';

/**
 * Retrieves search parameters from the global state to build a search URL.
 * Returns early with `{ isExchange: true }` if `isExchange` is active, skipping additional parameters.
 *
 * @returns {Object} - Search parameters including:
 *   - `isExchange` {boolean}: Indicates if the operation is an exchange.
 *   - `needsCityId` {boolean}: Specifies if the city ID is required in the search URL.
 *   - `origin` {string}: Origin ID.
 *   - `originCityId` {string}: City ID of the origin.
 *   - `destination` {string}: Destination ID.
 *   - `destinationCityId` {string}: City ID of the destination.
 *   - `departureIsOpenTicket` {boolean}: Indicates if the departure ticket is open.
 *   - `returnIsOpenTicket` {boolean}: Indicates if the return ticket is open.
 *   - `searchDepartureDate` {string}: Search query's departure date.
 *   - `searchReturnsDate` {string}: Search query's return date.
 *   - `departureDate` {string}: Purchase's departure date.
 *   - `returnDate` {string}: Purchase's return date.
 *   - `roundTrip` {boolean}: Indicates if it is a round trip.
 *   - `encodedPassengersSelection` {string}: Encoded string of passenger selection.
 */
export function getSearchParams() {
  const state = store.getState();

  const isExchange = state.purchase.get('isExchange');
  if (isExchange) return { isExchange };

  const passengersSelection = state.purchase.get('passengerSelection')?.toJS();
  const origin = state.purchase.getIn(['departs', 'origin', 'id']);
  const originCityId = state.purchase.getIn(['departs', 'origin', 'cityId']);
  const destination = state.purchase.getIn(['departs', 'destination', 'id']);
  const destinationCityId = state.purchase.getIn(['departs', 'destination', 'cityId']);
  const departureIsOpenTicket = state.purchase.getIn(['departs', 'fragments', 0, 'openTicket']);
  const returnIsOpenTicket = state.purchase.getIn(['returns', 'fragments', 0, 'openTicket']);
  const searchDepartureDate = state.search.get('departureDate');
  const searchReturnsDate = state.search.get('returnDate');
  const departureDate = state.purchase.getIn(['departs', 'departure']);
  const returnDate = state.purchase.getIn(['returns', 'departure']);
  const roundTrip = state.purchase.get('roundTrip');
  const encodedPassengersSelection =
    (passengersSelection && encodePassengers(passengersSelection)) || 'A1';
  const { features } = state.whitelabelConfig;

  return {
    isExchange,
    needsCityId: features.ENABLE_CITY_ID_IN_SEARCH,
    origin,
    originCityId,
    destination,
    destinationCityId,
    departureIsOpenTicket,
    returnIsOpenTicket,
    searchDepartureDate,
    searchReturnsDate,
    departureDate,
    returnDate,
    roundTrip,
    encodedPassengersSelection,
  };
}

/**
 * Generates a URL for redirecting to the search page based on provided parameters.
 * If the `isExchange` flag is true, it directly returns the `/exchange` URL.
 * Formats the departure and return dates based on ticket type (open or specific date).
 * Chooses between city ID or location ID depending on the `needsCityId` feature flag.
 * Constructs the URL using the origin, destination, and formatted date parameters.
 *
 * @param {Object} params - The parameters used to build the URL.
 * @param {boolean} params.needsCityId - Whether to use city IDs in the URL.
 * @param {string} params.origin - Origin ID for the search.
 * @param {string} params.originCityId - Origin city ID for the search.
 * @param {string} params.destination - Destination ID for the search.
 * @param {string} params.destinationCityId - Destination city ID for the search.
 * @param {boolean} params.departureIsOpenTicket - If true, uses an open ticket for the departure date.
 * @param {boolean} params.returnIsOpenTicket - If true, uses an open ticket for the return date.
 * @param {string} params.searchDepartureDate - The searched departure date in 'DD-MM-YYYY' format.
 * @param {string} params.searchReturnsDate - The searched return date in 'DD-MM-YYYY' format.
 * @param {string} params.departureDate - The actual departure date.
 * @param {string} params.returnDate - The actual return date.
 * @param {boolean} params.roundTrip - If true, a return trip is included.
 * @param {boolean} params.isExchange - If true, redirects to the exchange page.
 * @param {string} params.encodedPassengersSelection - Encoded passengers information for the URL.
 * @returns {string} - The generated URL for the search redirect.
 */
export const generateSearchRedirectUrl = (params = {}) => {
  const { isExchange } = params;
  if (isExchange) return '/exchange';

  const {
    needsCityId,
    origin,
    originCityId,
    destination,
    destinationCityId,
    departureIsOpenTicket,
    returnIsOpenTicket,
    searchDepartureDate,
    searchReturnsDate,
    departureDate,
    returnDate,
    roundTrip,
    encodedPassengersSelection = 'A1',
  } = params;

  const formattedDeparture = departureIsOpenTicket
    ? moment(searchDepartureDate, 'DD-MM-YYYY').format('DD-MMM-YY')
    : moment(departureDate).format('DD-MMM-YY');

  let url = `/search/${needsCityId ? originCityId : origin}/${
    needsCityId ? destinationCityId : destination
  }/${formattedDeparture}/`;

  if (roundTrip) {
    const formattedReturn = returnIsOpenTicket
      ? moment(searchReturnsDate, 'DD-MM-YYYY').format('DD-MMM-YY')
      : moment(returnDate).format('DD-MMM-YY');

    url += `${formattedReturn}/`;
  }

  return `${url}p/${encodedPassengersSelection}/departures`;
};
