import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCurrencySuffix } from 'utils/currency';
import { getABTest } from 'utils/taplytics';
import MainButton from '../ui/atoms/MainButton';
import { formatCurrency } from '../utils/Helpers';

const propTypes = {
  paymentType: PropTypes.string.isRequired,
  totalPrice: PropTypes.string.isRequired,
  isExchange: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isPointsPaying: PropTypes.bool,
  walletText: PropTypes.string,
};

const PaymentButton = ({
  paymentType,
  totalPrice,
  isExchange,
  onClick,
  isLoading,
  isPointsPaying,
  walletText,
}) => {
  const { t } = useTranslation();

  const formattedTotal = formatCurrency(Number(totalPrice), 2);
  const paymentsToIgnoreCurrency = ['free_pay'];
  const ABVariable = getABTest('payment', 'A');

  const paymentTypeAB = ABVariable === 'A' ? paymentType : `${paymentType}_${ABVariable}`;
  const text = isExchange
    ? t('payment:button.confirm_exchange')
    : `${t('payment:button.payment', { context: paymentTypeAB })} `;

  let totalTextComponent = (
    <>
      {text}
      {!paymentsToIgnoreCurrency.includes(paymentType) && totalPrice && (
        <>
          {formattedTotal} <small>{getCurrencySuffix()}</small>
        </>
      )}
    </>
  );

  if (!totalPrice) {
    totalTextComponent = t('payment:button.confirm_payment');
  }
  if (isPointsPaying) totalTextComponent = walletText;

  return <MainButton onClick={onClick} label={totalTextComponent} isLoading={isLoading} />;
};

PaymentButton.propTypes = propTypes;

export default PaymentButton;
