import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, Logo, Spacing } from '@reservamos/elements';

const importAll = (r) =>
  r.keys().reduce((acc, item) => {
    acc[item.replace('./', '').replace(/\.[^/.]+$/, '')] = r(item);
    return acc;
  }, {});

const assets = importAll(require.context('../../images/payment/yuno', false, /\.(png|jpe?g|svg)$/));

function YunoForm({ engine }) {
  let header;
  let content;
  let assetsSection;



  switch (engine) {
    case 'safetypay':
      header = 'Realice su pago en línea o en efectivo';
      content =
        'Realiza una transferencia a través de banca por internet o depósito a cuenta de SafetyPay en la entidad bancaria de tu preferencia dentro de las próximas horas después de realizar la reserva, de lo contrario tu reserva será cancelada.';
      assetsSection = <Logo imgSrc={assets.safetypay} alt="Safetypay" />;
      break;
    case 'pago_efectivo':
      header = 'Realice su pago en línea o en efectivo';
      content =
        'Paga en Yape, Plin, BBVA, BCP, Interbank, Scotiabank, BanBif, Caja Arequipa, y Banco Pichincha a través de la banca móvil, código QR o en agentes y bodegas de Red Digital, Kasnet, Agente Niubiz, Tambo+, Western Union y Disashop a cuenta de PagoEfectivo dentro de las próximas horas después de realizar la reserva, de lo contrario tu reserva será cancelada.';
      assetsSection = (
        <Spacing alignItems="center" size="S">
          <Spacing size="XS" vertical>
            <div>
              {[
                'yape',
                'plin',
                'bbva',
                'pichincha',
                'banbif',
                'bcp',
                'caja_arequipa',
                'interbank',
                'scotiabank',
              ].map((brand) => (
                <Icon key={brand} type={assets[brand]} alt={brand} />
              ))}
            </div>
            <div className="h-3 flex items-center">
              {['niubuz', 'disashop', 'red_digital', 'kasnet', 'tambo', 'western_union'].map(
                (brand) => (
                  <Icon key={brand} type={assets[brand]} size="XL" alt={brand} />
                ),
              )}
            </div>
          </Spacing>
          <Text weight="bold">vía</Text>

          <Icon type={assets.pago_efectivo} size="XXL" alt="Pago Efectivo" />
        </Spacing>
      );
      break;
    case 'cuotealo_bcp':
      header = 'Realice su pago en cuotas';
      content = '¡Solo para clientes BCP con cuenta de ahorros en soles o dólares!';
      assetsSection = <Logo imgSrc={assets.cuotealo} alt="Cuotealo" />;
      break;
    default:
      header = 'Unknown';
      content = 'Unknown';
  }

  return (
    <Spacing vertical size="S">
      <Text weight="bold" size="L">
        {header}
      </Text>
      {assetsSection || ''}
      <Text size="S" color="grayMedium">
        {content}
      </Text>
    </Spacing>
  );
}

YunoForm.propTypes = {
  engine: PropTypes.string.isRequired,
};

export default YunoForm;
