import React from 'react';
import { Box, FlatButton, Gap, Icon, Spacing, Text } from '@reservamos/elements';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AnimationOverview from '../../atoms/AnimationOverview';
import './DepartureTripPicked.scss';

/**
React functional component that represents a selected departure trip
  @param {object} props - The component's props
  @param {string} props.originCity - The origin city of the trip
  @param {string} props.destinationCity - The destination city of the trip
  @param {string} props.departureDate - The departure date of the trip (optional)
  @param {string} props.departureTime - The departure time of the trip (optional)
  @param {string} props.departureDuration - The duration of the trip
  @param {string} props.departureLineLogo - The logo of the departure line
  @param {boolean} props.isOpenTicket - Whether the ticket is open or not
  @param {function} props.onClick - The function to be called when the user clicks the button to redirect to the detailed view of the selected trip
  @returns {JSX.Element} - The DepartureTripPicked component
*/
const DepartureTripPicked = ({
  originCity,
  destinationCity,
  departureDate,
  departureTime,
  departureDuration,
  departureLineLogo,
  isOpenTicket,
  onClick,
}) => {
  const { t } = useTranslation('trips');

  const contentTypes = [
    <img
      src={departureLineLogo}
      height="20px"
      width="auto"
      style={{ height: '20px', width: 'auto' }}
    />,
    <Gap alignItems="center">
      <Text
        mobileSize="S"
        maxWidthEllipsis={120}
        mobileMaxWidthEllipsis={100}
        textTransform="capitalize"
      >
        {originCity}
      </Text>
      -
      <Text
        mobileSize="S"
        maxWidthEllipsis={120}
        mobileMaxWidthEllipsis={100}
        textTransform="capitalize"
      >
        {destinationCity}
      </Text>
    </Gap>,
  ];

  return (
    <div className="departure-trip">
      <div className="departure-trip-header">
        <Box
          paddingHorizontal="L"
          paddingVertical="M"
          paddingHorizontalMobile="M"
          paddingVerticalMobile={isOpenTicket ? 'S' : 'M'}
          hasShadow
          borderRadius="M"
        >
          <Spacing
            size="S"
            responsiveSize="XS"
            justifyContent="space-between"
            isResponsive={!isOpenTicket}
            alignItems={!isOpenTicket ? null : 'center'}
          >
            <Spacing size="M" responsiveSize="S" justifyContent="space-between" alignItems="center">
              <Gap alignItems="center" columnGap="S" responsiveColumnGap="XS">
                <Icon type="Success" color="success" mobileSize="S" />
                <Text mobileSize="M" size="L" weight="bold">
                  {t('trips:selected_trip')}
                </Text>
              </Gap>

              <FlatButton mobileSize="S" size="M" type="discount" onClick={onClick}>
                {t('trips:redirect_selected_trip')}
              </FlatButton>
            </Spacing>

            {!isOpenTicket && (
              <Spacing justifyContent="space-between" alignItems="center" size="S">
                <Text mobileSize="S" weight="semibold" italic textTransform="capitalize">
                  {departureDate}
                </Text>

                <Text mobileSize="S" weight="semibold" italic>
                  {departureTime}
                </Text>
              </Spacing>
            )}

            {isOpenTicket && (
              <Text mobileSize="S" size="L" weight="bold" color="grayMedium">
                {t('trips:open_ticket_trip')}
              </Text>
            )}
          </Spacing>
        </Box>
      </div>

      {!isOpenTicket && (
        <div className="departure-trip-bottom">
          <AnimationOverview contentTypes={contentTypes} />
          <Text mobileSize="S">{departureDuration}</Text>
        </div>
      )}
    </div>
  );
};

DepartureTripPicked.propTypes = {
  originCity: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  departureTime: PropTypes.string.isRequired,
  departureDuration: PropTypes.string.isRequired,
  departureLineLogo: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
};

export default DepartureTripPicked;
