// eslint-disable-next-line import/prefer-default-export
export const PURCHASE_SESSION_EXTENDED = 'Purchase Session Extended';
export const FIRST_DATA_PASSENGER_SWITCH_CLICKED = 'First Passenger Data Switch Clicked';
export const SWITCH_VALUE_PROP = 'Switch Value';
export const TICKET_INFO_BAR_DETAILS_CLICKED = 'InfoBar Details Clicked';
export const CHECKOUT_DETAILS_CLICKED = 'BookingReview Details Clicked';
export const SEARCH_CHANGED = 'Search Changed';
export const WALLET_PRICE_SWITCHED = 'Wallet Price Switched';
export const FILTERS_MODAL_OPENED = 'Filters Modal Opened';
export const RECOMMENDED_TRIPS_LOADED = 'Recommended Trips Loaded';
export const RECOMMENDED_TRIP_SELECTED = 'Recommended Trip Selected';
export const CONTINUE_WITH_SEATS = 'Continue With Seats';
export const FINGERPRINT_REQUESTED = 'Fingerprint Requested';
export const TERMINAL_GEOLOCATION_CLICKED = 'Terminal Geolocation Clicked';
export const PRICE_ALERT_CREATED = 'Price Alert Created';
export const PRICE_ALERT_OPENED = 'Price Alert Opened';
export const PRICE_ALERT_REDIRECTED = 'Price Alert Redirected';
export const PRICE_ALERT_FAILED = 'Price Alert Failed';
