import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spacing, Text } from '@reservamos/elements';
import 'styles/components/purchase/TicketsPanel';
import usePurchase from 'hooks/store/usePurchase';

/**
 * YunoPaymentInfo component renders the payment instructions and a button for Yuno payments.
 * The component includes:
 * - A text component that displays the payment instructions.
 * - A button that, when clicked, reloads the current page.
 * @returns {JSX.Element} The rendered component with payment instructions and a reload button.
 */
const YunoPaymentInfo = () => {
  const { state } = usePurchase();
  const { t } = useTranslation('payment');
  useEffect(() => {

    if (state === 'completed') {
      window.location.reload();
    }
  }, [state]);

  const handleClick = () => {
    window.location.reload();
  };

  return (
    <div className="tickets-panel-content">
      <Spacing vertical size="S">
        <Text size="M" weight="bold">
          {t('yuno_payment_info.instructions')}
        </Text>
        <Button onClick={handleClick} text={t('yuno_payment_info.button')} variant="primary" />
      </Spacing>
    </div>
  );
};

export default YunoPaymentInfo;
