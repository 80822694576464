import React from 'react';
import { Icon, Modal, Spacing, Text } from '@reservamos/elements';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InfoText from '../atoms/InfoText';
import './ExchangeInfoModal.scss';

const ExchangeInfoModal = () => {
  const { t } = useTranslation('exchange');
  const { env } = useSelector((state) => state.whitelabelConfig);
  const isGFA = env.brand === 'gfa';

  return (
    <Spacing size="XS" vertical>
      <Modal
        title={isGFA ? t('modal_info.title_gfa') : t('modal_info.title_iamsa')}
        modalTrigger={
          <>
            <Spacing size="XS" alignItems="baseline">
              <Icon type="CircleInfo" color="info" size="S" />
              <Text underline color="accent" weight="semibold" size="S">
                {isGFA ? t('modal_info.label_gfa') : t('modal_info.label_iamsa')}
              </Text>
            </Spacing>
          </>
        }
      >
        <Spacing vertical size="L">
          <Text>{t('modal_info.find_the_data')}</Text>
          <Spacing vertical size="S">
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              {t('modal_info.first')}
            </InfoText>
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              <Trans t={t} i18nKey="modal_info.second">
                <b />
              </Trans>
            </InfoText>
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              {t('modal_info.third')}
            </InfoText>
            <InfoText icon="Amenity" textSize="M" alignment="baseline" iconColor="accent">
              <Trans t={t} i18nKey={isGFA ? 'modal_info.fourth_gfa' : 'modal_info.fourth_iamsa'}>
                <b />
              </Trans>
            </InfoText>
          </Spacing>
          {isGFA ? <i className="operation-gfa" /> : <i className="operation-iamsa" />}
        </Spacing>
      </Modal>
    </Spacing>
  );
};

export default ExchangeInfoModal;
