import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { seatChangeTracker, exchangeSeatChangeTracker } from 'metrics/user-analytics/purchase';
import SeatSelector from 'components/purchase/SeatSelector';

const propTypes = {
  purchase: PropTypes.shape({
    isExchange: PropTypes.bool.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    isFetchingDetails: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    departs: PropTypes.object.isRequired,
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
      }),
    ).isRequired,
    returns: PropTypes.object.isRequired,
    allowsSeatSelection: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    roundTrip: PropTypes.bool.isRequired,
  }),
  fetchTripsDetails: PropTypes.func.isRequired,
  selectSeats: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  goToDepartureSeats: PropTypes.func.isRequired,
  updateSeat: PropTypes.func.isRequired,
  allowsSeatSelection: PropTypes.bool.isRequired,
  departureFragments: PropTypes.array.isRequired,
  returnFragments: PropTypes.array.isRequired,
  hasDetails: PropTypes.bool.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isExchange: PropTypes.bool.isRequired,
  isLockingTickets: PropTypes.bool,
  fetchingDetails: PropTypes.bool,
  selectedSeats: PropTypes.array,
  layout: PropTypes.object,
  seats: PropTypes.array,
  diagramType: PropTypes.string,
  isPetFriendlyTrip: PropTypes.bool,
  lockedSeats: PropTypes.array,
  isLoading: PropTypes.bool,
  isOpenTicket: PropTypes.bool,
  minPassengers: PropTypes.number,
  canGoBack: PropTypes.bool,
  canResetPassengers: PropTypes.bool,
  resetPassengers: PropTypes.func.isRequired,
  isPurchaseView: PropTypes.bool,
  tripPricing: PropTypes.object,
  hasSeats: PropTypes.bool,
  tripId: PropTypes.string,
  resultsLayout: PropTypes.bool,
  onClose: PropTypes.func,
  trip: PropTypes.object,
  clearError: PropTypes.func,
  fetchingDetailsError: PropTypes.bool,
  passengerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ),
  firstFloorReclination: PropTypes.string,
  secondFloorReclination: PropTypes.string,
};

class ReturnSeats extends Component {
  constructor(props) {
    super(props);

    this.finishedSeatSelection = this.finishedSeatSelection.bind(this);
  }

  componentDidMount() {
    const { allowsSeatSelection, canResetPassengers, resetPassengers, passengers, isPurchaseView } =
      this.props;

    this.getReservationDetails();
    if (!isPurchaseView) return;

    if (canResetPassengers) {
      resetPassengers(passengers);
    }

    if (!allowsSeatSelection) {
      this.finishedSeatSelection();
    }
  }

  getReservationDetails() {
    const {
      fetchTripsDetails,
      departureFragments,
      returnFragments,
      hasDetails,
      isFetchingDetails,
    } = this.props;

    if (!(hasDetails || isFetchingDetails)) {
      fetchTripsDetails({
        departureFragments,
        returnFragments,
      });
    }
  }

  finishedSeatSelection() {
    const { purchase, next, isExchange, resultsLayout } = this.props;

    if (isExchange) {
      exchangeSeatChangeTracker(purchase, 'return');
    } else {
      seatChangeTracker(purchase, 'return', resultsLayout ? 'results' : 'purchase');
    }

    next();
  }

  render() {
    const {
      selectSeats,
      sendNotification,
      goToDepartureSeats,
      updateSeat,
      passengers,
      diagramType,
      fetchingDetails,
      isLockingTickets,
      selectedSeats = [],
      layout,
      seats,
      lockedSeats,
      isLoading,
      isPetFriendlyTrip,
      isOpenTicket,
      allowsSeatSelection,
      minPassengers,
      canGoBack,
      tripPricing,
      hasSeats,
      tripId,
      resultsLayout,
      onClose,
      trip,
      clearError,
      fetchingDetailsError,
      passengerTypes,
      firstFloorReclination,
      secondFloorReclination,
      purchase,
    } = this.props;

    const busPassengers = passengers?.filter((p) => p.category !== 'infant');
    const loading = !allowsSeatSelection || isLockingTickets || !hasSeats || isLoading;

    return (
      <SeatSelector
        tripId={tripId}
        allowsSeatSelection={allowsSeatSelection}
        diagramType={diagramType}
        way="return"
        fetchingDetails={fetchingDetails}
        isPetFriendly={isPetFriendlyTrip}
        layout={layout}
        passengers={busPassengers}
        selectedSeats={selectedSeats}
        lockedSeats={lockedSeats}
        selectSeats={selectSeats}
        sendNotification={sendNotification}
        onFinishedSelection={this.finishedSeatSelection}
        onBackAction={goToDepartureSeats}
        isOpenTicket={isOpenTicket}
        seats={seats}
        minPassengers={minPassengers}
        updateSeat={updateSeat}
        isLoading={loading}
        canGoBack={canGoBack}
        tripPricing={tripPricing}
        passengerTypes={passengerTypes}
        resultsLayout={resultsLayout}
        onClose={onClose}
        hasSeats={hasSeats}
        trip={trip}
        clearError={clearError}
        fetchingDetailsError={fetchingDetailsError}
        firstFloorReclination={firstFloorReclination}
        secondFloorReclination={secondFloorReclination}
        roundTrip={purchase?.roundTrip}
      />
    );
  }
}

ReturnSeats.propTypes = propTypes;

export default ReturnSeats;
