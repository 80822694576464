import React from 'react';
import PropTypes from 'prop-types';
import errorMonitoring from '../errorMonitoring';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    errorMonitoring.notify({ error: new Error(error), info });
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
