import { connect } from 'react-redux';
import { setError } from '@/actions';
import { newPurchase } from '@/actions/purchase';

import ExchangeTripList from './ExchangeTripList';

const mapStateToProps = ({ config, exchange, tripFilters }) => {
  const {
    ticket: { nit, openTicket },
    passengers,
    walletOriginalType,
  } = exchange;
  const operationNumbers = passengers?.map((passenger) => passenger.transporterKey);
  return {
    operationNumbers,
    nit,
    openTicket,
    paymentPlans: config.paymentPlans || [],
    installmentsMinAmount: config.installmentsMinAmount,
    tripFilters,
    walletOriginalType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setError: () => dispatch(setError(1, 'Ocurrio un error al buscar viajes', 'warning', false)),
    newPurchase: (originSlug, seenPrice, exchangeData) =>
      dispatch(newPurchase(originSlug, null, 'A1', seenPrice, false, true, exchangeData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeTripList);
