import { connect } from 'react-redux';
import { EXCHANGE_TYPEAHEAD_CLEAR, EXCHANGE_DATEPICKER_CHANGE } from 'constants/ActionTypes';
import { errorTripExchange, validateExchange } from '../../../actions/exchange';
import ExchangeSecondStep from './ExchangeSecondStep';

const mapStateToProps = ({ purchase, exchange, code }) => {
  const { originSlug, originDisplay, destinationDisplay, destinationSlug, date } = exchange.trip;
  const currentTicket = exchange.ticket.operationNumber;
  const { errorTrip } = exchange;

  return {
    isFetching: purchase.get('isFetching'),
    token: purchase.get('token'),
    originSlug,
    originDisplay,
    destinationDisplay,
    destinationSlug,
    date,
    currentTicket,
    code,
    errorTrip,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDate: (date) => dispatch({ type: EXCHANGE_DATEPICKER_CHANGE, date }),
    clearTypeahead: (origin, destination) =>
      dispatch({
        type: EXCHANGE_TYPEAHEAD_CLEAR,
        origin,
        destination,
      }),
    errorTripExchange: (errorTrip) => dispatch(errorTripExchange(errorTrip)),
    validateExchange: (history, operationNumbers, NIT, origin, destination, recaptchaToken) =>
      dispatch(
        validateExchange(history, operationNumbers, NIT, origin, destination, recaptchaToken),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeSecondStep);
