import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * Calculates the age of a passenger based on their date of birth.
 *
 * @param {string|Date} dateOfBirth - The passenger's date of birth.
 * @returns {number|null} The calculated age in years, or null if no date of birth is provided.
 */
function getPassengerAge(dateOfBirth) {
  if (!dateOfBirth) return null;

  const today = moment();
  const birthDate = moment(dateOfBirth);
  const age = today.diff(birthDate, 'years');
  return age;
}

getPassengerAge.propTypes = {
  dateOfBirth: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default getPassengerAge;
