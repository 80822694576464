import React from 'react';
import { LinkButton, MessageBox, Text, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import usePurchase from 'hooks/store/usePurchase';

function MinorCompleteDisclaimer() {
  const { t } = useTranslation('purchase');
  const purchase = usePurchase();
  const tickets = purchase?.departs?.fragments?.[0]?.tickets || [];

  return (
    <>
      {tickets.map((ticket) => {
        if (!ticket?.formMinorUrl) return null;
        return (
          <MessageBox key={`ticket-${ticket.id}`} borderColor="warning" roundCorners widthFull>
            <Spacing size="S" vertical alignItems="flex-start">
              <Text>{t('text.minor_disclaimer_message_cds')}</Text>
              <LinkButton
                borderRadius
                variant="accent"
                href={ticket.formMinorUrl ?? '#'}
                padding="S"
                text={t('text.minor_disclaimer_cta_cds')}
              />
            </Spacing>
          </MessageBox>
        );
      })}
    </>
  );
}

export default MinorCompleteDisclaimer;
