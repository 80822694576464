/**
 * Factory function to create a Yuno instance
 * @returns {Object} An object with methods to interact with Yuno
 *
 * Available methods:
 * ==================
 *
 * initialize(publicKey: string): Promise<void>
 * --------------------------------------------
 * Initializes the Yuno SDK with the provided public key
 *
 * getInstance(): YunoInstance|null
 * --------------------------------
 * Returns the current Yuno instance
 *
 */
function Yuno() {
  /** @type {YunoInstance|null} */
  let yunoInstance = null;

  /**
   * Initialize the Yuno SDK
   * @param {string} publicKey - The public key for Yuno SDK
   * @returns {Promise<void>}
   */
  const initialize = async (publicKey) => {
    const script = document.createElement('script');
    script.src = 'https://sdk-web.y.uno/v1/static/js/main.min.js';
    document.body.appendChild(script);
    yunoInstance = await new Promise((resolve) => {
      script.onload = async () => {
        let yuno = window.Yuno;
        yuno = await yuno.initialize(publicKey);
        resolve(yuno);
      };
    });
  };

  /**
   * Get the Yuno instance
   * @returns {YunoInstance|null}
   */
  const getInstance = () => {
    return yunoInstance;
  };

  return {
    initialize,
    getInstance,
  };
}

const yuno = Yuno();

export default yuno;
