import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, Gap, Spacing, Text } from '@reservamos/elements';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import PricingRow from '../../ui/atoms/PricingRow';
import discountGFA from '../../images/brands-unique/gfa/early-discount.png';
import discountETN from '../../images/brands-unique/etn/discountETN.png';

const earlyDiscountIcons = {
  etn: discountETN,
  gfa: discountGFA,
  default: 'emojiSparkles',
};

const propTypes = {
  reservation: PropTypes.shape({
    tripSlug: PropTypes.string.isRequired,
    tickets: PropTypes.array.isRequired,
    pricing: PropTypes.shape({
      total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
  }).isRequired,
  totalLabel: PropTypes.string.isRequired,
  externalCouponCode: PropTypes.string,
  selectedSeatsBeforeCheckout: PropTypes.array,
  displayPricingBeforeCheckout: PropTypes.bool,
};

/**
 * Row component
 * @param {string} label - Label to show
 * @param {string} discountLabel - Discount label to show
 * @param {number} price - Price to show
 * @param {boolean} hasDiscount - If has discount
 * @param {boolean} isMain - If is main
 * @returns {JSX.Element}
 */
const RowComponent = ({ label, discountLabel, price, hasDiscount, isMain, icon, primary }) => {
  return (
    <PricingRow
      label={label}
      discountLabel={discountLabel}
      isMain={isMain}
      hasDiscount={hasDiscount}
      price={price}
      primary={primary}
      icon={icon}
      sign={getCurrencyPrefix()}
      currency={getCurrencySuffix()}
    />
  );
};

RowComponent.propTypes = {
  label: PropTypes.string.isRequired,
  discountLabel: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasDiscount: PropTypes.bool,
  isMain: PropTypes.bool,
  icon: PropTypes.string,
  primary: PropTypes.bool,
};

/**
 * Component to show pricing for each passenger
 * @param {*} props - reservation, totalLabel, externalCouponCode
 * @param {*} props.reservation - reservation object
 * @param {*} props.totalLabel - total label
 * @param {*} props.externalCouponCode - external coupon code
 * @returns {React.Component} - PurchasePricingPassengerTrip component
 */
const PurchasePricingPassengerTrip = ({
  reservation,
  totalLabel,
  externalCouponCode,
  selectedSeatsBeforeCheckout,
  displayPricingBeforeCheckout,
}) => {
  const {
    PRECHECKOUT_SEATS,
    PURCHASE_PRICING_DETAILED_DISCOUNT,
    FLAT_FARE_ENABLED,
    FLAT_FARE_CONTEXT,
    HIDE_PASSENGER_CATEGORY_COPIES,
  } = useWhitelabelFeatures();

  const env = useWhitelabelEnvs();
  const { t } = useTranslation();
  const { tickets, pricing, tripSlug } = reservation;
  const discountsTypes = {
    single_trip_flat_fare_discount: 'single_trip_flat_fare_discount',
    departure_on_round_trip_flat_fare_discount: 'departure_on_round_trip_flat_fare_discount',
    single_on_round_trip_flat_fare_discount: 'single_on_round_trip_flat_fare_discount',
  };

  /**
   * Get category translation
   * @param {boolean} isAdjacentSeat
   * @param {string} category
   * @returns
   */
  const getCategoryTranslation = (isAdjacentSeat, category) => {
    const context = isAdjacentSeat ? 'adjacent_seat' : category;
    return t('category', { context });
  };

  const { total } = pricing;
  const totalSeatsPrice = selectedSeatsBeforeCheckout.reduce(
    (sum, seat) => sum + parseFloat(seat.price),
    0,
  );

  const finalTotal =
    PRECHECKOUT_SEATS && displayPricingBeforeCheckout ? totalSeatsPrice || 0 : total;

  return (
    <div className="section-content">
      {tickets.map((item) => {
        const passengerName = `${item.firstName} ${item.lastName || ''}`;
        const hasEarlyDiscount = item.discountReason === 'compra anticipada';
        let discountLabels = { label: t('purchase:label.discount') };
        if (PURCHASE_PRICING_DETAILED_DISCOUNT && item.discountReason && item.discountPercent) {
          discountLabels = {
            label: t('purchase:label.your_discount_savings', {
              reason: item.discountReason,
            }),
            discountLabel: t('purchase:label.maximum_discount_percentage', {
              percent: item.discountPercent,
            }),
          };
        }
        return (
          <Fragment key={`${tripSlug}-${item.passengerId}`}>
            <div key={`${tripSlug}-${item.passengerId}-name`} className="passenger-row">
              <Text weight="semibold" size="S">
                {passengerName}
              </Text>
              <Gap columnGap="S">
                {!HIDE_PASSENGER_CATEGORY_COPIES && (
                  <Text elementType="span" size="S" color="grayMedium">
                    {getCategoryTranslation(item.isAdjacentSeat, item.category)}
                  </Text>
                )}
                {FLAT_FARE_ENABLED && discountsTypes[item.discountType] && (
                  <Text elementType="span" size="S" color="loyaltyLight" weight="semibold" italic>
                    {t('label.reward_ticket', { context: FLAT_FARE_CONTEXT })}
                  </Text>
                )}
              </Gap>
            </div>
            <RowComponent
              key={`${tripSlug}-${item.passengerId}-price`}
              label={t('purchase:label.price')}
              price={item.amount}
            />
            {item.discountAmount > 0 &&
              (hasEarlyDiscount ? (
                <RowComponent
                  label={t('trips:early_discount')}
                  price={item.discountAmount}
                  hasDiscount
                  primary
                  icon={earlyDiscountIcons[env.brand] ?? earlyDiscountIcons.default}
                />
              ) : (
                <RowComponent
                  label={discountLabels.label}
                  discountLabel={discountLabels.discountLabel}
                  price={item.discountAmount}
                  hasDiscount
                />
              ))}
            {item.subtotal && (
              <RowComponent
                key={`${tripSlug}-${item.passengerId}-subtotal`}
                label={t('purchase:label.subtotal')}
                price={item.subtotal}
              />
            )}
            <RowComponent
              key={`${tripSlug}-${item.passengerId}-tax`}
              label={t('purchase:extra_price')}
              price={item.taxes}
            />
            {externalCouponCode && (
              <Spacing size="S" alignItems="center">
                <Text color="grayMedium">{t('label.coupon')}:</Text>
                <Badge hasShadow roundCorners type="success" smallPadding>
                  <Text color="white" elementType="span" size="S" weight="semibold">
                    {externalCouponCode}
                  </Text>
                </Badge>
              </Spacing>
            )}
            <RowComponent
              key={`${tripSlug}-${item.passengerId}-total`}
              label={t('purchase:label.total')}
              price={item.total}
            />
          </Fragment>
        );
      })}
      {PRECHECKOUT_SEATS &&
        tickets.length === 0 &&
        selectedSeatsBeforeCheckout.map((seat, index) => {
          if (!seat.price) return null;
          return (
            <Fragment key={seat}>
              <div className="passenger-row">
                <div className="flex gap-2">
                  <p className="text-sm font-semibold">{`${t('passengers:label.passenger')} ${
                    index + 1
                  }`}</p>
                  <p className="text-sm font-bold">{seat.category === 'VIP' && seat.category}</p>
                </div>
              </div>
              <RowComponent label={t('purchase:label.price')} price={seat.price} />
            </Fragment>
          );
        })}

      <RowComponent label={t(`purchase:label.${totalLabel}`)} price={finalTotal} isMain />
    </div>
  );
};

PurchasePricingPassengerTrip.propTypes = propTypes;

export default PurchasePricingPassengerTrip;
