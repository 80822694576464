/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { feedbackClass, hasFeedback } from 'utils/formValidations';
import 'styles/components/formRenderers';
import { InputRedux, SelectRedux, FilterableSelect } from '@reservamos/elements';

export const fieldRenderer = ({
  input,
  id,
  pattern,
  inputMode,
  meta,
  type,
  placeholder,
  cls,
  readonly = false,
  onClick,
  maxLength,
  isDisabled,
  placeholderOnly,
}) => {
  const { t } = useTranslation('errors');
  const errorMessage = t('field', { context: meta.error });

  return (
    <div className={`field-renderer ${cls}`}>
      <InputRedux
        {...input}
        id={id}
        type={type}
        label={placeholder}
        placeholder={placeholder}
        onClick={onClick}
        className={`form-input ${feedbackClass(meta)}`}
        readOnly={readonly}
        maxLength={maxLength}
        isDisabled={isDisabled}
        {...(pattern && { pattern })}
        {...(inputMode && { inputMode })}
        hasError={hasFeedback(meta)}
        errorMessage={errorMessage}
        placeholderOnly={placeholderOnly}
      />
    </div>
  );
};

fieldRenderer.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  onClick: PropTypes.func,
  cls: PropTypes.string,
  maxLength: PropTypes.string,
  isDisabled: PropTypes.bool,
  pattern: PropTypes.string,
  inputMode: PropTypes.string,
  placeholderOnly: PropTypes.bool,
  label: PropTypes.string,
};

fieldRenderer.defaultProps = {
  cls: '',
  isDisabled: false,
};

export const selectRenderer = ({ input, meta, options, cls, isDisabled, placeholder, label }) => {
  const { t } = useTranslation('errors');
  const errorMessage = t('field', { context: meta.error });
  return (
    <div className={`${cls}`}>
      <SelectRedux
        options={options}
        {...input}
        isDisabled={isDisabled}
        label={label}
        placeholder={placeholder}
        hasError={hasFeedback(meta)}
        errorMessage={errorMessage}
      />
    </div>
  );
};

selectRenderer.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  cls: PropTypes.string,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

selectRenderer.defaultProps = {
  isDisabled: false,
};

/**
 * Renders a country selector with a flag icon and a dropdown for selecting country codes.
 *
 * @param {Object} params - The parameters for the renderer.
 * @param {Object} params.input - The input object containing value and onChange handler.
 * @param {Object} params.meta - The meta object containing error and touched states.
 * @param {boolean} params.isDisabled - Flag to disable the select input.
 * @param {string} params.cls - Additional CSS classes for styling.
 * @param {Array} params.options - The options for the select dropdown.
 * @returns {JSX.Element} The rendered country selector component.
 */
export const countryRenderer = ({ input, meta, isDisabled, cls, options }) => {
  const { t } = useTranslation('');
  const hasError = Boolean(meta.error);
  const errorMessage = t('errors:field', { context: meta.error });
  const countryFlagCdn = `https://cdn.jsdelivr.net/gh/lipis/flag-icon-css@master/flags/4x3/${input.value?.toLowerCase()}.svg`;

  return (
    <div className={`${cls} country-code`}>
      <div className="flag-container">
        <img src={countryFlagCdn} alt={input.value} className="flag-container-img" />
      </div>
      <SelectRedux
        options={options}
        label={t('passengers:label.phone_code')}
        placeholder={t('passengers:label.phone_code')}
        hasError={meta.touched && hasError}
        errorMessage={errorMessage}
        {...input}
        isDisabled={isDisabled}
      />
    </div>
  );
};

countryRenderer.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  cls: PropTypes.string,
};

countryRenderer.defaultProps = {
  cls: '',
};

export const FilterableSelectRenderer = ({ input, meta, ...rest }) => {
  return <FilterableSelect value={input.value} onChange={input.onChange} meta={meta} {...rest} />;
};

FilterableSelectRenderer.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};
