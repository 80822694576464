import { List, fromJS } from 'immutable';
import isCurrentPurchase from 'utils/purchase/validateCurrentPurchaseByTrip';
import getSeats from 'utils/purchase/getSeats';
import * as types from 'constants/ActionTypes';
import purchaseAllowsSeatSelection from 'utils/purchase/purchaseAllowsSeatSelection';
import { exchangeAttemptTracker } from 'metrics/user-analytics/purchase';
import {
  getPurchaseSelectedSeats,
  getUpdatedSeats,
  preventSelectPetFriendly,
  reservationHasSelectedSeats,
} from 'utils/seats';
import wayIsOpenTicket from 'utils/wayIsOpenTicket';
import { handleSetPassengers } from '../features/purchase/redux/handlers';
import { defaultPurchase } from '../store';

export default function purchase(purchase = {}, action) {
  switch (action.type) {
    case types.SET_SELECTED_PLAN:
      return purchase.set('selectedPlan', action.selectedPlan);

    case types.SET_INSTALLMENTS_CARD:
      return purchase.set('selectedInstallmentsCard', action.card);

    case types.EXPIRE_PURCHASE:
      return purchase.set('isExpired', true);

    case types.SET_FAILED_STATE_PURCHASE:
      return purchase.set('state', 'failed');

    case types.REQUEST_PURCHASE:
      return purchase.set('isFetching', true).set('isFetchingRedirect', true);

    case types.RECEIVE_PURCHASE: {
      /**
       * Avoid to merge into state if token has changed.
       * This behavior is not expected since requests can be overrode and the action purchase could be an old state.
       * Issue: https://reservamossaas.atlassian.net/browse/ENT-389
       */
      if (purchase.get('token') && purchase.get('token') !== action.purchase.token) {
        return purchase;
      }

      const purchaseHasTickets = (rawPurchase) =>
        rawPurchase.departs.fragments.every(
          (fragment) =>
            fragment.tickets.length > 0 &&
            fragment.tickets.every((ticket) => ticket?.state === 'locked'),
        ) &&
        (!rawPurchase.returns ||
          rawPurchase.departs.fragments.every(
            (fragment) =>
              fragment.tickets.length > 0 &&
              fragment.tickets.every((ticket) => ticket?.state === 'locked'),
          ));

      const currentPaymentOption = purchase.get('selectedPaymentOption');

      let paymentType = action.purchase.availablePayments.includes('reservamos_pay')
        ? 'reservamos_pay'
        : currentPaymentOption;

      if (action.purchase.availablePayments.includes('free_pay')) {
        paymentType = 'free_pay';
      }

      const selectedInstallmentsCard = purchase.get('selectedInstallmentsCard');
      let newSelectedInstallmentsPlan;

      const card = selectedInstallmentsCard;
      const months = action.purchase.monthlySelectedPlan;
      const { paymentPlans } = action.purchase;

      // Get local state of selected installments plan
      const localSelectedPlanOption = purchase.get('monthlySelectedPlan');

      if (months > 1) {
        newSelectedInstallmentsPlan = {
          ...paymentPlans[card][months],
          card,
          months,
        };
      }

      const preSelectedSeats = action.purchase.preSelectedSeats || {};
      const { departs, returns } = purchase?.toJS();

      const hasPreSelectedSeats = Object.keys(preSelectedSeats).length;
      const selectedSeats = getPurchaseSelectedSeats({
        purchaseData: action.purchase,
        currentSelectedSeats: {
          depart: departs?.selectedSeats || [],
          return: returns?.selectedSeats || [],
        },
      });
      const newPurchaseState = purchase
        .mergeDeep(
          fromJS({
            ...action.purchase,
            isFetching: false,
            isFetchingRedirect: false,
            isUpdating: false,
            loaded: true,
            hasTickets: purchaseHasTickets(action.purchase),
            allowsSeatSelection: purchaseAllowsSeatSelection(action.purchase),
            lastUpdated: action.receivedAt,
            hasSelectedSeats: hasPreSelectedSeats && Boolean(Object.keys(selectedSeats).length),
            ...(localSelectedPlanOption > 1 && { monthlySelectedPlan: localSelectedPlanOption }),
            ...(newSelectedInstallmentsPlan && {
              selectedInstallmentsPlan: newSelectedInstallmentsPlan,
            }),
            departs: {
              ...action.purchase.departs,
              selectedSeats: selectedSeats.departure,
            },
            returns: {
              ...action.purchase.returns,
              selectedSeats: selectedSeats.return,
            },
            preSelectedSeats,
          }),
        )
        /**
         * Estos valores se establecen por separado para evitar que con mergeDeep se dupliquen valores al ser arrays
         * Ej.: paymentMethods: [{type:'oxxo'},{type: 'coppel_pay}] -> paymentMethods: [{type:'coppel_pay'},{type: 'coppel_pay}]
         * Esto porque en action.purchase se recibe paymentMethods: [{type:'coppel_pay'}] haciendo merge en la posición 0 y dejando coppel también en la pos 1
         *
         * Otro ejemplo es el caso de fragments, si se editan los asientos se mantienen los tickets anteriores aunque se hayan borrado
         */
        .set('availablePayments', fromJS(action.purchase.availablePayments)) // TODO remove this line when we remove the old payment methods
        .set('paymentMethods', fromJS(action.purchase.paymentMethods))
        .set('selectedPaymentOption', paymentType)
        .setIn(['departs', 'fragments'], fromJS(action.purchase.departs.fragments))
        .update((newState) => {
          // Override return fragments if they are present in the returns way
          if (purchase.getIn(['returns', 'fragments'])) {
            return newState.setIn(
              ['returns', 'fragments'],
              fromJS(action.purchase.returns.fragments),
            );
          }
          return newState;
        });

      if (newPurchaseState.get('isExchange')) {
        exchangeAttemptTracker(newPurchaseState.toJS());
      }

      return newPurchaseState;
    }

    case types.RESET_PURCHASE:
      return defaultPurchase.getInstance().set('lastUpdated', action.receivedAt);

    case types.UPDATE_PURCHASE:
      return purchase.set('isUpdating', action.updating);

    case types.SET_BUS_CATEGORIES: {
      if (action.tripSlug && !isCurrentPurchase(purchase, action)) {
        return purchase;
      }

      return purchase.set('busCategories', action.busCategories);
    }

    case types.SET_SEAT_MAP: {
      if (!isCurrentPurchase(purchase, action)) {
        return purchase;
      }

      const { layout, diagramType } = action;
      return purchase.mergeIn([action.way], {
        layout: fromJS(layout),
        seats: getSeats(action.layout),
        diagramType,
      });
    }

    case types.REQUEST_TRIPS_DETAILS:
      return purchase // prettier-ignore
        .set('isFetchingDetails', true)
        .setIn([action.way, 'fetchingDetails'], true);

    case types.RECEIVE_TRIPS_DETAILS: {
      if (!isCurrentPurchase(purchase, action)) {
        return purchase;
      }

      const updatedPurchase = purchase.mergeIn([action.way], {
        fetchingDetails: false,
        hasDetails: true,
      });
      const isFetchingDetails =
        updatedPurchase.getIn(['departs', 'fetchingDetails']) ||
        updatedPurchase.getIn(['returns', 'fetchingDetails']);
      const hasDetails =
        updatedPurchase.getIn(['departs', 'hasDetails']) &&
        updatedPurchase.getIn(['returns', 'hasDetails']);
      return updatedPurchase.merge({
        isFetchingDetails: Boolean(isFetchingDetails),
        hasDetails: Boolean(hasDetails),
      });
    }

    case types.REFRESHING_BUS:
      return purchase.set('refreshingBus', action.isRefreshing);

    case types.PURCHASE_POST_PASSENGERS:
      return purchase.set('savingPassengers', true);

    case types.PURCHASE_SET_PASSENGERS: {
      const { passengers, token } = action;
      /**
       * Set passengers only if the purchase token is still the same as the request.
       * This behavior is not expected since requests can be overrode and the action purchase could be an old state.
       * Issue: https://reservamossaas.atlassian.net/browse/ENT-389
       */
      if (purchase.get('token') === token) {
        return handleSetPassengers(purchase, passengers);
      }
      return purchase;
    }

    case types.FINISHED_SEAT_SELECTION: {
      return purchase
        .set('savingPassengers', false)
        .set('passengers', fromJS(action.passengersWithSeats));
    }

    case types.PURCHASE_SET_PAYMENT_CARDS: {
      return purchase.merge({
        purchaserPaymentCards: action.cards,
      });
    }

    case types.FETCHING_TAXPAYER_ID:
      return purchase.set('fetchingTaxpayerId', action.updating);

    case types.PURCHASE_SET_PAYMENT_PLANS: {
      const anyPlan = Boolean(Object.keys(action.paymentPlans).length);
      return purchase
        .set('paymentPlans', action.paymentPlans)
        .set('qualifiesForMonthlyInstallments', anyPlan);
    }

    case types.UPDATE_SEAT: {
      // Update the selected seats in the purchase state

      const { seat, way } = action;
      const selectedSeats = purchase.getIn([way, 'selectedSeats']);
      const newSelectedSeats = selectedSeats.toJS();
      const updatedSeats = getUpdatedSeats({ selectedSeats: newSelectedSeats, seat });
      const updatedPurchase = purchase.setIn([way, 'selectedSeats'], List(updatedSeats));

      return updatedPurchase;
    }

    case types.SELECT_SEATS: {
      const seats = preventSelectPetFriendly(action.seats);

      const updatedPurchase = purchase.setIn([action.way, 'selectedSeats'], List(seats));
      let hasSelectedSeats = reservationHasSelectedSeats(updatedPurchase.get('departs'));

      if (
        updatedPurchase.get('roundTrip') &&
        !wayIsOpenTicket(updatedPurchase.get('returns').toJS())
      ) {
        hasSelectedSeats =
          hasSelectedSeats && reservationHasSelectedSeats(updatedPurchase.get('returns'));
      }

      return updatedPurchase.merge({ hasSelectedSeats });
    }

    case types.REQUEST_TICKETS: {
      const updatedPurchase = purchase.setIn([action.way, 'isLockingTickets'], true);

      return updatedPurchase.set('isLockingTickets', true);
    }

    case types.RECEIVE_TICKETS: {
      let ticketsErrorType;
      if (action.error) {
        ticketsErrorType = /occupied/g.test(action.error) ? 'occupied' : 'other';
      }
      const roundTrip = purchase.get('roundTrip');
      const updatedPurchase = purchase.mergeIn([action.way], {
        isLockingTickets: false,
        tickets: List(action.tickets),
      });

      return updatedPurchase.merge({
        hasTickets:
          updatedPurchase.getIn(['departs', 'tickets']).size > 0 &&
          (!roundTrip || updatedPurchase.getIn(['returns', 'tickets']).size > 0),

        isLockingTickets: ['departs', 'returns'].some((way) => {
          const newLockingTickets = updatedPurchase.getIn([way, 'isLockingTickets']);
          return newLockingTickets;
        }),
        ticketsErrorType,
      });
    }

    case types.CLEAR_TICKETS_ERROR_TYPE: {
      return purchase.merge({
        ticketsErrorType: null,
      });
    }

    case types.TOGGLE_INSURANCE:
      return purchase.set('updatingInsurance', action.updating);

    case types.UPDATE_USING_WALLET:
      return purchase.set('updatingWallet', action.updating);

    case types.REQUEST_DISCOUNT:
      return purchase.set('applyingDiscount', true);

    case types.RECEIVE_DISCOUNT:
      return purchase.set('applyingDiscount', false);

    case types.SELECT_PAYMENT_OPTION:
      return purchase.set('selectedPaymentOption', action.option);

    case types.SELECT_PAYMENT_METHOD:
      return purchase.set('selectedPaymentMethod', action.method);

    case types.SELECT_MONTHLY_PLAN:
      return purchase.merge({
        selectedInstallmentsPlan: action.installmentsPlan,
        monthlySelectedPlan: action.installmentsPlan.months,
      });

    case types.PURCHASE_SET_EXPIRATION:
      return purchase.set('expiresAt', action.expiration);

    case types.PURCHASE_EXTENDED_EXPIRATION: {
      const expirationExtension = purchase.get('expirationExtension').toJS();
      return purchase
        .set('expirationExtension', {
          ...expirationExtension,
          extendedAt: action.extendedAt,
          available: false,
        })
        .set('expiresAt', action.expiresAt)
        .set('currentTime', action.currentTime);
    }

    case types.SET_EMAIL_STATUS:
      return purchase.merge({
        sentEmailStatus: action.status,
      });

    case types.PURCHASE_INSURANCES_CHECKED:
      return purchase.set('insurancesChecked', true);

    case types.SET_EXCHANGE_OPERATION:
      return purchase.set('isExchange', true);

    case types.TOGGLE_CARBON_OFFSET:
      return purchase.set('updatingCarbonOffset', action.updating);

    case types.UPDATING_WALLET_TYPE:
      return purchase.set('isUpdatingWalletType', action.updating);

    case types.RECEIVE_WALLET_TYPE:
      return purchase
        .set('walletType', action.walletType)
        .set('flatFareAvailable', action.flatFareAvailable);

    case types.TOGGLE_FLAT_FARE:
      return purchase.set('isUpdatingFlatFare', action.updating);

    case types.SET_YUNO_SESSION_DATA:
      return purchase.set('yuno', action.yunoSessionData);

    default:
      return purchase;
  }
}
