import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/fr';
import 'moment/locale/nl';

/**
 * Initializes the moment library with the specified language and hour format.
 *
 * @param {'es-MX'|'pt-BR'|'en-US'|'fr-FR'|'nl-NL'} defaultLanguage - The default language to use for moment.
 * @param {'12'|'24'} hourFormat - The hour format to use ('12' for 12-hour format, '24' for 24-hour format).
 */
export default function initMoment(defaultLanguage = 'es', hourFormat) {
  moment.locale('es');
  let lng;
  let format;
  const momentHourFormat = hourFormat === '12' ? 'hh:mm A' : 'HH:mm';
  const i18nextLng =
    typeof window !== 'undefined' && window.localStorage
      ? window.localStorage.getItem('i18nextLng') || defaultLanguage
      : defaultLanguage;

  switch (i18nextLng) {
    case 'es-MX':
      lng = 'es';
      format = {
        longDateFormat: {
          LT: momentHourFormat,
          LLLL: `dddd, D [de] MMMM [antes de las] ${momentHourFormat}`,
          LLL: `dddd, DD [de] MMMM ${momentHourFormat}`,
        },
        monthsShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
      };
      break;
    case 'pt-BR':
      lng = 'pt-br';
      format = {
        longDateFormat: {
          LT: momentHourFormat,
          LLLL: `dddd, D [de] MMMM [antes das] ${momentHourFormat}`,
          LLL: `dddd, DD [de] MMMM ${momentHourFormat}`,
        },
        monthsShort: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Maio',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
      };
      break;
    case 'en-US':
      lng = 'en';
      format = {
        longDateFormat: {
          LT: momentHourFormat,
          LTS: hourFormat === '12' ? 'h:mm:ss A' : 'HH:mm:ss',
          L: 'MM/DD/YYYY',
          LL: 'MMMM Do YYYY',
          LLL: `MMMM Do YYYY ${momentHourFormat}`,
          LLLL: `dddd, MMMM Do YYYY ${momentHourFormat}`,
        },
        monthsShort: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      };
      break;
    case 'fr-FR':
      lng = 'fr';
      format = {
        longDateFormat: {
          LT: momentHourFormat,
          LTS: hourFormat === '12' ? 'h:mm:ss A' : 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: `D MMMM YYYY ${momentHourFormat}`,
          LLLL: `dddd D MMMM YYYY ${momentHourFormat}`,
        },
        monthsShort: [
          'Janv',
          'Févr',
          'Mars',
          'Avr',
          'Mai',
          'Juin',
          'Juil',
          'Août',
          'Sept',
          'Oct',
          'Nov',
          'Déc',
        ],
      };
      break;
    case 'nl-NL':
      lng = 'nl';
      format = {
        longDateFormat: {
          LT: momentHourFormat,
          LTS: hourFormat === '12' ? 'h:mm:ss A' : 'HH:mm:ss',
          L: 'DD-MM-YYYY',
          LL: 'D MMMM YYYY',
          LLL: `D MMMM YYYY ${momentHourFormat}`,
          LLLL: `dddd D MMMM YYYY ${momentHourFormat}`,
        },
        monthsShort: [
          'Jan',
          'Feb',
          'Mrt',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dec',
        ],
      };
      break;
    default:
      lng = 'es';
      format = {
        longDateFormat: {
          LT: momentHourFormat,
          LLLL: `dddd, D [de] MMMM [antes de las] ${momentHourFormat}`,
          LLL: `dddd, DD [de] MMMM ${momentHourFormat}`,
        },
        monthsShort: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic',
        ],
      };
  }

  moment.updateLocale(lng, format);
  moment.locale(lng); // Set the active locale
}
