import 'babel-polyfill';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@reservamos/elements';
import App from 'components/App';
import ErrorBoundary from 'components/ErrorBoundary';
import GrowthBookProvider from 'components/GrowthBookProvider';
import { MercadoPagoInstanceProvider } from 'components/mercadoPago';
import OpenPayLoader from 'components/OpenPayLoader';
import CSSVariablesProvider from 'components/CSSVariablesProvider/CSSVariablesProvider';
import LoyaltyProgramsProvider from './loyalty/context/LoyaltyProgramsProvider';
import CustomI18nextProvider from './CustomI18nextProvider';
import { getInstance } from './utils/taplytics';
import initializeClientServices from './initializers';
import fetchWhiteLabelConfig from './api/fetchWhitelabelConfig';
import '../vendor';
import './styles/theme/_design-system';
import './styles/main';
import './tailwind';

const contentToRender = (whitelabelConfig, storeInstance) => {
  render(
    <ErrorBoundary>
      <Provider store={storeInstance}>
        <GrowthBookProvider>
          <CustomI18nextProvider>
            <BrowserRouter>
              <OpenPayLoader
                config={{
                  ...whitelabelConfig.env.openPay,
                  openPayRegion: whitelabelConfig.features.OPENPAY_REGION,
                }}
              >
                <MercadoPagoInstanceProvider config={whitelabelConfig.env.mercadoPago}>
                  <CSSVariablesProvider
                    theme={whitelabelConfig.env.theme}
                    funnelStyle={whitelabelConfig.features.FUNNEL_STYLE}
                    funnelStyleBrandVariation={whitelabelConfig.env.brand.toUpperCase()}
                  >
                    <ThemeProvider theme={whitelabelConfig.env.theme}>
                      <LoyaltyProgramsProvider>
                        <App />
                      </LoyaltyProgramsProvider>
                    </ThemeProvider>
                  </CSSVariablesProvider>
                </MercadoPagoInstanceProvider>
              </OpenPayLoader>
            </BrowserRouter>
          </CustomI18nextProvider>
        </GrowthBookProvider>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('app'),
  );
};

fetchWhiteLabelConfig().then(async (whitelabelConfig) => {
  // initialize third party libraries/plugins
  const { storeInstance } = await initializeClientServices(whitelabelConfig);
  if (whitelabelConfig.env.taplytics.enabled) {
    const taplytics = getInstance();
    taplytics.propertiesLoaded(() => {
      contentToRender(whitelabelConfig, storeInstance);
    });
  } else {
    contentToRender(whitelabelConfig, storeInstance);
  }
});
// TODO catch error, what should we do in that case?
