import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Spacing, IconText } from '@reservamos/elements';
import PointsSlider from 'ui/molecules/PointsSlider';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import useDotersSlider from './useDotersSliders';
import usePurchase from '../../../hooks/store/usePurchase';
import { formatDotersPoints } from '../../../utils/doters';

const DotersSlider = () => {
  const purchase = usePurchase();

  const {
    doters: { profile, wallet, onApplyPointsDiscount, onRemovePointsDiscount, isServiceAvailable },
  } = useLoyaltyPrograms();

  const {
    minPointsToBeEnable,
    isJustFlatFare,
    totalBalance,
    formattedUsableBalance,
    error,
    canApplyPoints,
    handleOnSubmitPoints,
    handleOnChange,
    handleOnDelete,
    handleOnEdit,
    handleOnCancel,
    maxPoints,
    sliderPoints,
    disabled,
  } = useDotersSlider({
    wallet,
    profile,
    onApplyPointsDiscount,
    onRemovePointsDiscount,
    purchaseTotal: purchase.total,
  });

  const { t: tPayment } = useTranslation('payment');

  const headerTitle =
    disabled || isJustFlatFare
      ? tPayment('label.points_applied')
      : tPayment('doters_instructions.select_quantity_to_use');

  if (
    purchase.walletType !== 'doters' ||
    (maxPoints <= 0 && !wallet.minPointsToUse) ||
    purchase.isUpdating ||
    profile.availablePoints === undefined // TODO: change this for wallet.isUpdating when works
  ) {
    return null;
  }
  if (profile.availablePoints < minPointsToBeEnable)
    return <Text weight="semibold">{tPayment('label.not_enough_doters_points')}</Text>;
  if (maxPoints < minPointsToBeEnable && !isJustFlatFare)
    return <Text weight="semibold">{tPayment('label.can_not_use_doters_points')}</Text>;
  if (!isServiceAvailable && !isJustFlatFare) {
    return <Text weight="semibold">{tPayment('label.can_not_use_doters_points')}</Text>;
  }

  return (
    <Spacing id="dotersSlider" vertical size="L">
      <PointsSlider
        defaultExpanded
        handleOnSubmitPoints={handleOnSubmitPoints}
        maxPoints={maxPoints}
        handleOnChange={handleOnChange}
        handleOnDelete={handleOnDelete}
        handleOnEdit={handleOnEdit}
        handleOnCancel={handleOnCancel}
        sliderPoints={sliderPoints}
        inputPointsValue={sliderPoints}
        balance={totalBalance}
        disabled={disabled || isJustFlatFare}
        canApplyPoints={canApplyPoints}
        pointsApplied={wallet.usedBalance || isJustFlatFare}
        isLoading={wallet.isUpdating}
        headerTitle={headerTitle}
        inputLabelText={tPayment('label.doters_to_use')}
        sliderHeader={
          <Spacing vertical size="S">
            <Spacing justifyContent="center">
              {canApplyPoints ? (
                <Text color="loyalty" weight="bold" size="L" mobileSize="M">
                  {tPayment('label.you_are_applying', {
                    sliderPoints: formatDotersPoints(sliderPoints),
                  })}
                </Text>
              ) : (
                <Text color="loyalty" weight="bold" size="L" mobileSize="M">
                  {tPayment('label.you_have_doters', {
                    points: formattedUsableBalance,
                  })}
                </Text>
              )}
            </Spacing>

            {Boolean(wallet.minPointsToUse) && (
              <IconText
                iconType="checkGreen"
                label={tPayment('label.points_applied_flat_fare', {
                  pointsUsed: formatDotersPoints(wallet.minPointsToUse),
                  context: 'quantity',
                })}
                iconSize="M"
                fontSize="M"
              />
            )}
          </Spacing>
        }
        minPointsText={`0 ${tPayment('label.doter_points_min')}`}
        maxPointsText={`${formatDotersPoints(maxPoints)} ${tPayment('label.doter_points_max')}`}
        pointsAppliedText={tPayment('label.points_applied', {
          pointsUsed: formatDotersPoints(wallet.usedPoints + wallet.minPointsToUse),
          context: 'quantity',
        })}
        error={error}
        editButtonText={tPayment('button.modify_doters_points')}
        editButtonEnabled={!isJustFlatFare}
        deleteButtonEnabled={!isJustFlatFare}
        modifierClass="doters"
        loyalty="doters"
        hideModifiersButtons={isJustFlatFare}
        availablePoints={profile.availablePoints}
      />
    </Spacing>
  );
};

export default DotersSlider;
