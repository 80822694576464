import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text } from '@reservamos/elements';
import PassengerPolicies from './purchase/PassengerPolicies';
import CheckboxRenderer from './CheckboxRenderer';

function AgreementCheckboxFields() {
  const { t } = useTranslation('purchase');
  const { copies } = useSelector((state) => state.whitelabelConfig);

  return (
    <form>
      <Field
        name="legalTerms"
        id="legalTermsCheckbox"
        type="checkbox"
        // TODO: FIX STYLING
        label={<PassengerPolicies isCheckbox />}
        component={CheckboxRenderer}
      />
      <Field
        name="promotionalTerms"
        id="promotionalTermsCheckbox"
        type="checkbox"
        label={
          <Text size="S">{t('text.promotional_terms', { provider: copies.provider.name })}</Text>
        }
        component={CheckboxRenderer}
      />
    </form>
  );
}

export default reduxForm({
  form: 'purchaser',
})(AgreementCheckboxFields);
