import { reduxForm } from 'redux-form';
import { createValidator, required } from '../../../utils/formValidations';
import TransferForm from './TransferForm';

const validate = createValidator({
  bank: required,
  personType: required,
});

export default reduxForm({
  form: 'transfer',
  destroyOnUnmount: false,
  initialValues: {
    personType: '0',
  },
  validate,
})(TransferForm);
