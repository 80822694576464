/* eslint-disable camelcase */

const amenities = {
  bathroom: 'Bathroom',
  bathroomSigns: 'BathroomSigns',
  tv: 'Tv',
  personal_screen: 'Tv',
  movies: 'Tv',
  entertainment: 'Tv',
  wifi: 'Wifi',
  coffee: 'Coffee',
  eticket: 'Eticket',
  charger_usb: 'Eticket',
  wardrobe: 'Wardrobe',
  bus: 'Bus',
  amenity: 'Amenity',
  air_conditioning: 'AirConditioning',
  free_snacks: 'FreeSnacks',
  free_pet_travel: 'FreePetTravel',
  free_infant_travel: 'FreeInfantTravel',
  reclining_spot_122: 'Recline',
  reclining_spot_130: 'Recline',
  reclining_spot_140: 'Recline',
  seat_width_43: 'Distance',
  seat_width_45: 'Distance',
  seat_width_50: 'Distance',
  distance_between_seats_67: 'Distance',
  distance_between_seats_88: 'Distance',
  foot_support: 'FootSupport',
  comfortable: 'Recline',
};

export default amenities;
