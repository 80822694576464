import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createSearch, setParams, applyCoupon } from '@/actions/search';
import { setTripFilter, setTripSortOrder } from '@/actions/tripFilters';
import getSearchAnalyticsData from 'utils/search/getSearchAnalyticsData';
import parseQueryString from 'utils/queryString/parseQueryString';
import getSearchStep from 'utils/search/getSearchStep';
import isSearchPolling from 'utils/search/isSearchPolling';
import hasRedirectTrips from 'utils/trips/hasRedirectTrips';
import SearchProcessor from './SearchProcessor';
import { getMomentFromSearchDateParam } from '../../../utils/search/searchDateParam';
import { isRecommendedTripsEnabled } from '../../../utils/userPreferences';
import { resetPayment, resetPurchase } from '../../../actions/purchase';

const mapStateToProps = (state, ownProps) => {
  const {
    search,
    cities,
    terminals,
    trips,
    whitelabelConfig: { features },
  } = state;
  const { match, location } = ownProps;

  const {
    originSlug,
    destinationSlug,
    departureDate,
    returnDate,
    passengers = 'A1',
  } = match.params;
  const { lodgingProvider = 'none' } = location.search;
  const { returnIsOpenTicket = false } = parseQueryString(location.search);
  const places = { ...cities, ...terminals };

  // This two params are got from the search state to avoid missed url params
  const origin = places[search.get('originId')] || {};
  const destination = places[search.get('destinationId')] || {};

  const roundTrip = !!returnDate;
  const departureId = search.getIn(['departure', 'id']);
  const departures = trips.get(departureId);
  const returnId = search.getIn(['return', 'id']);
  const couponLoading = search.get('couponLoading');
  const couponCode = search.get('couponCode');
  const { isExchange } = parseQueryString(location.search);

  const couponCodeParam = !isExchange && parseQueryString(location.search).coupon;

  let searchIsFetching = !(departureId && !search.getIn(['departure', 'isFetching']));
  let returns;
  const headerData = {};

  if (roundTrip) {
    searchIsFetching = searchIsFetching || !(returnId && !search.getIn(['return', 'isFetching']));
    returns = trips.get(returnId);
  }

  let currentWayTrips;
  let searchHasRedirectTrips = false;
  let isReturns;

  /**
   * Origins and destinations can be Cities or Terminals
   * If is terminal, we need to display the cityName attribute
   * If is a city, we display the name attribute
   * So, in any case, we're displaying the city name
   */

  const originPlaceName = origin.cityName || origin.name;
  const destinationPlaceName = destination.cityName || destination.name;

  switch (getSearchStep(ownProps.location.pathname)) {
    case 'providers':
      headerData.homeButton = true;
      headerData.title = 'line_to_travel';
      headerData.subtitle = `${originPlaceName} - ${destinationPlaceName}`;
      headerData.origin = `${originPlaceName}`;
      headerData.destination = `${destinationPlaceName}`;
      headerData.dates = getMomentFromSearchDateParam(departureDate).format('DD MMM YYYY');

      if (returnDate) {
        headerData.dates += `- ${getMomentFromSearchDateParam(returnDate).format('DD MMM YYYY')}`;
      }
      break;
    case 'providersReturns':
      headerData.homeButton = false;
      headerData.title = 'line_to_return';
      headerData.subtitle = `${originPlaceName} - ${destinationPlaceName}`;
      headerData.origin = `${originPlaceName}`;
      headerData.destination = `${destinationPlaceName}`;
      headerData.dates = getMomentFromSearchDateParam(departureDate).format('DD MMM YYYY');

      if (returnDate) {
        headerData.dates += ` - ${getMomentFromSearchDateParam(returnDate).format('DD MMM YYYY')}`;
      }
      break;
    case 'departures':
      headerData.homeButton = false;
      headerData.title = 'select_your_schedule';
      headerData.subtitle = `${originPlaceName} → ${destinationPlaceName}`;
      headerData.origin = `${originPlaceName}`;
      headerData.destination = `${destinationPlaceName}`;
      headerData.dates = getMomentFromSearchDateParam(departureDate).format('DD MMM YYYY');
      currentWayTrips = departures;
      isReturns = headerData.homeButton;
      break;
    case 'returns':
      headerData.homeButton = false;
      headerData.title = 'select_your_return_schedule';
      headerData.subtitle = `${originPlaceName} → ${destinationPlaceName}`;
      headerData.origin = `${destinationPlaceName}`;
      headerData.destination = `${originPlaceName}`;
      headerData.dates = getMomentFromSearchDateParam(returnDate).format('DD MMM YYYY');
      currentWayTrips = returns;
      isReturns = !headerData.homeButton;
      break;
    default:
      headerData.homeButton = true;
      headerData.title = '';
      headerData.subtitle = '';
      headerData.origin = '';
      headerData.destination = '';
      headerData.dates = '';
      isReturns = false;
  }

  if (features.TRIP_REDIRECTION_LEAD && currentWayTrips) {
    const currentTripList = currentWayTrips.getIn(['buses', 'trips']);
    searchHasRedirectTrips = currentTripList ? hasRedirectTrips(currentTripList) : false;
  }

  return {
    originSlug,
    destinationSlug,
    headerData,
    isReturns,
    departureDate,
    returnDate,
    passengers,
    roundTrip,
    searchIsFetching,
    couponLoading,
    couponCode,
    couponCodeParam,
    searchIsPolling: isSearchPolling(search, departures, returns, roundTrip),
    analyticsData: getSearchAnalyticsData({ search, places, departures, returns, lodgingProvider }),
    searchHasRedirectTrips,
    returnIsOpenTicket,
    features,
    includeRecommendedTrips: isRecommendedTripsEnabled(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  /**
   * Dispatch setParams redux action
   * @param {string} origin - Departure slug
   * @param {string} destination - Destination slug
   * @param {Object} passengers - Passenger object
   * @param {Date} departs - Departure date
   * @param {Date} returns - Return date
   * @returns Redux action
   */
  setSearchParams: (origin, destination = null, passengers, departs, returns = null) =>
    dispatch(setParams(origin, destination, passengers, departs, returns)),

  /**
   * Dispatches the creation of a new request of search
   * @param {Way} way - Departure or return
   * @param {string} origin - Departure id
   * @param {string} destination - Destination id
   * @param {Date} date - Date of departure or return
   * @param {string} passengers - Passengers A2-C1-I1 => { adult: 2, child: 1, infant: 1 }
   * @param {boolean} roundTrip - Is roundtrip
   * @param {boolean} isOpen - Is open date
   * @returns - Redux action
   */
  createSearch: (
    way,
    origin,
    destination,
    date,
    passengers,
    roundTrip,
    isOpen,
    includeRecommendedTrips,
  ) => {
    dispatch(
      createSearch(
        way,
        origin,
        destination,
        date,
        passengers,
        roundTrip,
        isOpen,
        includeRecommendedTrips,
      ),
    );
  },

  resetPurchase: () => {
    dispatch(resetPurchase());
  },

  resetPayment: () => {
    dispatch(resetPayment());
  },

  /**
   * Dispatch set coupon request action
   * @param {string} couponCode - Coupon code
   * @returns - Redux action
   */
  fetchCoupon: (couponCode, t) => applyCoupon(couponCode, false, dispatch, t),

  setSortOrder: (sortBy) => dispatch(setTripSortOrder(sortBy)),

  /**
   * Set categories filter.
   * @param {Object} options - Options object.
   * @param {Array} options.categories - Categories array.
   */
  setCategoriesFilter: ({ categories }) => {
    dispatch(setTripFilter('categories', categories, true));
  },
});

export default compose(
  withTranslation('search'),
  connect(mapStateToProps, mapDispatchToProps),
)(SearchProcessor);
