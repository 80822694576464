import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentLang, setLang } from 'utils/lang';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import { Icon, Dropdown } from '@reservamos/elements';
import coFlag from 'images/languages/colombia-flag.svg';
import usFlag from 'images/languages/usa-flag.svg';
import mxFlag from 'images/languages/mexico-flag.svg';
import brFlag from 'images/languages/brazil-flag.svg';
import frFlag from 'images/languages/france-flag.svg';
import peFlag from 'images/languages/peru-flag.svg';

const languageCodes = [
  {
    flag: usFlag,
    title: 'English',
    lng: 'en-US',
  },
  {
    flag: mxFlag,
    title: 'Español',
    lng: 'es-MX',
  },
  {
    flag: peFlag,
    title: 'Español',
    lng: 'es-PE',
  },
  {
    flag: coFlag,
    title: 'Español',
    lng: 'es-CO',
  },
  {
    flag: frFlag,
    title: 'Français',
    lng: 'fr-FR',
  },
  {
    flag: brFlag,
    title: 'Português',
    lng: 'pt-BR',
  },
];

const AdaptiveLanguageSelector = () => {
  const features = useWhitelabelFeatures();
  const env = useWhitelabelEnvs();
  const { i18n } = useTranslation();
  const currentLangCode = CurrentLang(); // Get the current language code

  // Filter available languages based on environment settings
  const availableLanguages = languageCodes.filter((code) => env.lang?.available.includes(code.lng));

  // Early return if language change is not allowed
  if (!features.ALLOW_TO_CHANGE_LANGUAGE || availableLanguages.length < 2) return null;

  /**
   * Change the language.
   * @param {Object} langCode - The language code.
   */
  const changeLanguage = (langCode) => {
    setLang(i18n, langCode.lng);

    // Create a URL object from the current location
    const url = new URL(window.location);

    // Remove only the 'lang' search param
    url.searchParams.delete('lang');

    // Update the URL without the 'lang' search param
    window.history.replaceState({}, '', url);

    // Reload the page
    window.location.reload();
  };

  // Toggle behavior when only two languages are available
  if (availableLanguages.length === 2) {
    const nextLang = availableLanguages.find((lang) => lang.lng !== currentLangCode);

    return (
      <button
        className="flex transform transition-transform duration-250 ease-[cubic-bezier(0.55, 0.085, 0.68, 0.53)] hover:scale-115"
        onClick={() => changeLanguage(nextLang)}
        title={nextLang.title}
      >
        <Icon type={nextLang.flag} />
      </button>
    );
  }

  // Dropdown behavior when more than two languages are available
  const currentLang = languageCodes.find((langCode) => langCode.lng === currentLangCode);

  return (
    <Dropdown trigger={<Icon type={currentLang.flag} />}>
      <>
        {availableLanguages.map((language) => (
          <button
            className="flex items-center p-2.5 my-1.5 border-none bg-white cursor-pointer transition-all duration-200 ease-in hover:bg-gray-200 hover:-translate-y-0.5 active:bg-gray-200 active:translate-y-0 w-full text-left"
            onClick={() => changeLanguage(language)}
            key={language.lng}
          >
            <Icon type={language.flag} />
            <p className="pl-1">{language.title}</p>
          </button>
        ))}
      </>
    </Dropdown>
  );
};

export default AdaptiveLanguageSelector;
