import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AncillariesSwitch from '../../ui/atoms/AncillariesSwitch';
import useInsuranceConfig from '../../hooks/useInsuranceConfig';

const InsuranceAncillarySwitch = ({
  checked,
  isLoading,
  message,
  title,
  price,
  singlePrice,
  onChange,
}) => {
  const { icon, detailsUrl } = useInsuranceConfig();
  const { t } = useTranslation();

  return (
    <AncillariesSwitch
      icon={icon}
      href={detailsUrl}
      linkText={detailsUrl ? t('insurance:terms_conditions') : ''}
      checked={checked}
      isLoading={isLoading}
      onChange={onChange}
      message={message}
      title={title}
      price={price}
      singlePrice={singlePrice}
      plusTaxes
    />
  );
};

InsuranceAncillarySwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  singlePrice: PropTypes.bool,
};

export default InsuranceAncillarySwitch;
