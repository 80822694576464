import { List, fromJS } from 'immutable';

export const transformAndFilterKeys = ({
  data,
  isFormPassenger = false,
  ignoredKeys = [],
  allowedKeys = [],
}) => {
  const transformedData = data.set(
    'isoCountryCode',
    isFormPassenger ? data.get('nationality') : data.get('isoCountryCode'),
  );

  return transformedData.filter(
    (_, key) => !ignoredKeys.includes(key) && allowedKeys.includes(key),
  );
};

export const comparePassengers = (passengers, formPassengers) => {
  const ignoredKeys = ['name', 'seats', 'nationalityId', 'phoneCode', 'phone', 'phoneCountry'];
  const allowedKeys = List(passengers.first().keys());

  const normalize = (items, isFormPassenger) =>
    items.map((item) =>
      // eslint-disable-next-line prettier/prettier
      transformAndFilterKeys({ data: item, isFormPassenger, ignoredKeys, allowedKeys }));

  const normalizedPassengers = normalize(passengers, false);
  const normalizedFormPassengers = normalize(fromJS(formPassengers), true);


  return List(normalizedPassengers).equals(normalizedFormPassengers);
};
