import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlatButton, Icon, Spacing, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import './NoResults.scss';
import DayControls from 'components/search/DayControls';
import NerbyTerminals from '../atoms/NerbyTerminals';

const NoResults = ({ way, providerId, isProviderList }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const history = useHistory();
  const { t } = useTranslation('search');

  const handleGotoHome = () => {
    history.push('/');
  };

  return (
    <div className="no-results-container">
      <div className="no-results-wrapper">
        <Spacing size="XL" vertical alignItems="flex-start">
          <Spacing size="XL" vertical>
            <Spacing alignItems="center">
              <Icon type="Calendar" size="L" />
              <Text size="L" weight="bold">
                {t('schedules_not_found')}
              </Text>
            </Spacing>
            {features.SHOW_MIN_PRICES && (
              <Spacing vertical>
                <Text>{t('look_for_another_date')}</Text>
                <DayControls way={way} providerId={providerId} isProviderList={isProviderList} />
              </Spacing>
            )}
            {features.USE_NERBY_TERMINALS && <NerbyTerminals titleSize="M" />}
          </Spacing>
          <FlatButton onClick={handleGotoHome} size="S">
            {t('new_search')}
          </FlatButton>
        </Spacing>
      </div>
    </div>
  );
};

NoResults.propTypes = {
  way: PropTypes.string.isRequired,
  providerId: PropTypes.string,
  isProviderList: PropTypes.bool,
};

export default NoResults;
