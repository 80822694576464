/* eslint-disable import/prefer-default-export */
import store from '@/store';

export const supportedLangs = {
  'en': 'en-US',
  'co': 'es-CO',
  'es': 'es-MX',
  'pt': 'pt-BR',
  'en-US': 'en-US',
  'es-CO': 'es-CO',
  'es-MX': 'es-MX',
  'es-PE': 'es-PE',
  'pt-BR': 'pt-BR',
  'fr-FR': 'fr-FR',
  'english': 'en-US',
  'spanish': 'es-MX',
};

const I18_LSKEY = 'i18nextLng';

const CurrentLang = ({ shouldReadDefaultLanguageFromStore = true } = {}) => {
  let defaultLang = 'es-MX';

  if (shouldReadDefaultLanguageFromStore) {
    const { env } = store.getState().whitelabelConfig;
    defaultLang = supportedLangs[env.lang.default] || 'es-MX';
  }

  const lsLang =
    typeof window !== 'undefined' &&
    window.localStorage &&
    window.localStorage.getItem('i18nextLng');
  const current = supportedLangs[lsLang] || defaultLang;

  return current;
};

/**
 * Set the language selected in local storage and i18n instance
 * @param {Object} i18n - i18n instance to be used
 * @param {String} language - Language as string
 */
const setLang = (i18n, language) => {
  const currentLang = i18n.language;

  if (currentLang === supportedLangs[language] || language === null || !supportedLangs[language])
    return;

  // eslint-disable-next-line no-unused-expressions
  typeof window !== 'undefined' && window.localStorage
    ? window.localStorage.setItem(I18_LSKEY, supportedLangs[language])
    : null;

  i18n.changeLanguage(supportedLangs[language]);
};

export { CurrentLang, setLang };
