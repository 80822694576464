import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Gap, Icon, Spacing, Text } from '@reservamos/elements';
import './PassengersHeading.scss';
import adultIcon from 'images/passengers/adult-icon.png';
import infantIcon from 'images/passengers/infant-icon.png';
import childIcon from 'images/passengers/child-icon.png';
import petBadge from 'images/badges/pet.svg';
import NewSeat from 'ui/atoms/NewSeat';
import { isPetFriendlySeat } from 'utils/purchase/seats';
import { useTranslation } from 'react-i18next';

/**
 * Render the seat number. It could be one or two depending on round trip
 * @param {Object} props
 * @param {Array} props.seats Seats
 * @returns JSX Element
 */
const SeatNumberWrapper = ({ seats }) => {
  const { t } = useTranslation();
  if (!seats || !seats.length) return null;
  return seats
    .filter((seat) => !!seat && !seat.isPickedAsAdjacent)
    .map((seat) => {
      return (
        <>
          <Spacing size="XS" alignItems="center">
            <NewSeat
              number={seat.number}
              type="selected"
              category={seat.category}
              showIcon={!isPetFriendlySeat(seat.category)}
              isSmall
              useNewDesign
            />
            {seat.seatFloor && (
              <Badge roundCorners type="gray">
                <Text elementType="span" size="S" weight="semibold">
                  {t('deck')}
                  <Text elementType="span" size="S" weight="bold">
                    {' '}
                    {seat.seatFloor}
                  </Text>
                </Text>
              </Badge>
            )}
          </Spacing>
          {seat.isAdjacentPicked && (
            <NewSeat
              number={seat.adjacentSeats.numbers[0]}
              type="selected"
              category="adjacent"
              showIcon
              isSmall
              useNewDesign
            />
          )}
        </>
      );
    });
};

/**
 * This component is used to render the heading of the passengers card
 * @param {Object} Props
 * @param {string} Props.title Title of the card
 * @param {string} Props.category Category of the passenger
 * @param {Array} Props.seats Seats
 * @param {node} Props.rightContent Content to be rendered on the right
 * @returns JSX Element
 */
const PassengersHeading = ({ title, rightContent, category, seats }) => {
  const icons = {
    default: adultIcon,
    general: adultIcon,
    infant: infantIcon,
    minor: childIcon,
    pet_friendly: petBadge,
  };

  return (
    <div className="passengers-heading">
      <Gap alignItems="center">
        {category && <Icon type={icons[category] || icons.default} />}
        <Text weight="bold">{title}</Text>
        {seats && <SeatNumberWrapper seats={seats} />}
      </Gap>
      {rightContent}
    </div>
  );
};

SeatNumberWrapper.propTypes = {
  seats: PropTypes.object.isRequired,
};

PassengersHeading.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  seats: PropTypes.number,
  rightContent: PropTypes.node,
};

PassengersHeading.defaultProps = {
  seats: [],
  rightContent: null,
};

export default PassengersHeading;
