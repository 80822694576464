import React, { useRef } from 'react';
import { Spacing, Text, Currency, FlatButton, Button, Input } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './PointsSlider.scss';
import AddPointsButton from '../AddPointsButton';
import { formatDotersPoints } from '../../../utils/doters';

const PointsSlider = ({
  sliderPoints,
  inputValue,
  balance,
  handleOnSubmitPoints,
  minPoints,
  maxPoints,
  handleOnChange,
  handleOnEdit,
  handleOnDelete,
  disabled,
  canApplyPoints,
  pointsApplied,
  isLoading,
  steps,
  sliderHeader,
  minPointsText,
  maxPointsText,
  errorMessage,
  editButtonText,
  deleteButtonText,
  hideModifiersButtons,
  loyalty,
  showAddPointsButton,
  rechargeURL,
  showInput,
  handleUpdateWallet,
  availablePoints,
}) => {
  const rangeInput = useRef(null);
  const { t } = useTranslation(['payment', 'loyalty']);

  const rangePercentage =
    sliderPoints > 0 ? ((sliderPoints - minPoints) * 100) / (maxPoints - minPoints) : 0;

  const minPointsApply = sliderPoints > minPoints;
  const showModifiers = !pointsApplied || !disabled;
  const disabledClass = (showModifiers && disabled) || isLoading ? 'slider-disabled' : '';
  const showCheck = !!(pointsApplied && disabled);
  const textColor = loyalty === 'doters' ? 'loyalty' : 'primary';
  const sliderPointsValue = !sliderPoints ? minPoints : sliderPoints;
  const remainingPoints = availablePoints - sliderPoints;

  const applyButton = (
    <Button
      onClick={() => handleOnSubmitPoints(sliderPoints)}
      isLoading={isLoading}
      variant={textColor}
      className="button-secondary"
      text={t('loyalty:apply')}
      isDisabled={!canApplyPoints}
      mobileSize="S"
    />
  );

  const pointButtonHandler = (
    <>
      {pointsApplied && sliderPoints === 0 ? (
        <FlatButton size="S" href="#" type="error" onClick={handleOnDelete}>
          {deleteButtonText || t('payment:button.doter_cancel')}
        </FlatButton>
      ) : (
        applyButton
      )}
    </>
  );

  return (
    <div className={`points-slider ${disabledClass}`}>
      <Spacing vertical size={pointsApplied ? 'S' : 'M'}>
        {showModifiers && (
          <Spacing size="S" vertical>
            {sliderHeader}
            <input
              className="rangeslider"
              ref={rangeInput}
              type="range"
              value={sliderPointsValue}
              onChange={handleOnChange}
              step={steps}
              min={minPoints}
              max={maxPoints}
              disabled={disabled || isLoading}
              style={{
                backgroundSize: `${rangePercentage}% 100%`,
              }}
              name="sliderRange"
            />

            <Spacing justifyContent="space-between" alignItems="center">
              <Text size="S" weight="semibold">
                {minPointsText}
              </Text>

              <Text size="S" weight="semibold">
                {maxPointsText}
              </Text>
            </Spacing>
            {showInput && (
              <div className="points-slider-input">
                <Input
                  onChange={handleOnChange}
                  label={t('payment:label.quantity_to_use')}
                  type="number"
                  value={inputValue}
                  name="inputPoints"
                  id="inputPoints"
                />
                {applyButton}
              </div>
            )}
          </Spacing>
        )}

        <Spacing vertical size="L" mobileSize="S">
          <Spacing size="XS" alignItems="items-center" justifyContent="space-between">
            <Spacing
              size="M"
              alignItems="center"
              isResponsive
              responsiveSize="XS"
              mobileAlign="flex-start"
            >
              <Spacing size="XS" alignItems="center">
                <Text
                  size="M"
                  mobileSize="S"
                  color={canApplyPoints ? textColor : 'grayLight'}
                  weight="bold"
                >
                  {t('payment:label.outstanding_to_pay')}
                </Text>
                <Currency
                  price={balance}
                  currency="MXN"
                  mobileSize="S"
                  size="M"
                  color={canApplyPoints ? textColor : 'grayLight'}
                  weight="bold"
                />
              </Spacing>
              {loyalty === 'doters' && (
                <Text
                  size="M"
                  mobileSize="S"
                  color="grayLight"
                  weight="bold"
                  style={{ marginLeft: '10px' }}
                >
                  {t('payment:label.remaining_doters_points')} {formatDotersPoints(remainingPoints)}
                </Text>
              )}
            </Spacing>
            <Spacing size="XS" alignItems="center">
              {!showCheck && !showInput && pointButtonHandler}
              {showAddPointsButton && (
                <AddPointsButton
                  rechargeURL={rechargeURL}
                  tooltipPosition="left"
                  handleUpdateWallet={handleUpdateWallet}
                />
              )}
            </Spacing>
          </Spacing>

          {errorMessage && (
            <Text size="S" elementType="span" color="error">
              {errorMessage}
            </Text>
          )}
        </Spacing>

        {pointsApplied ? (
          <Spacing alignItems="center" justifyContent="space-between" size="S">
            {!isLoading && pointsApplied && disabled && !hideModifiersButtons ? (
              <FlatButton size="S" href="#" type="accent" onClick={handleOnEdit}>
                {editButtonText}
              </FlatButton>
            ) : (
              ''
            )}
            {!isLoading && pointsApplied && disabled && !hideModifiersButtons && minPointsApply ? (
              <FlatButton size="S" href="#" type="error" onClick={handleOnDelete}>
                {deleteButtonText || t('payment:button.doter_cancel')}
              </FlatButton>
            ) : (
              ''
            )}
          </Spacing>
        ) : (
          ''
        )}
      </Spacing>
    </div>
  );
};

PointsSlider.propTypes = {
  sliderPoints: PropTypes.number,
  balance: PropTypes.number,
  minPoints: PropTypes.number,
  maxPoints: PropTypes.number,
  handleOnSubmitPoints: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnEdit: PropTypes.func,
  handleOnDelete: PropTypes.func,
  pointsApplied: PropTypes.number,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  steps: PropTypes.string,
  canApplyPoints: PropTypes.bool,
  sliderHeader: PropTypes.string,
  minPointsText: PropTypes.string,
  maxPointsText: PropTypes.string,
  errorMessage: PropTypes.string,
  editButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  loyalty: PropTypes.oneOf(['doters', 'costapass']),
  hideModifiersButtons: PropTypes.bool,
  showAddPointsButton: PropTypes.bool,
  rechargeURL: PropTypes.string,
  showInput: PropTypes.bool,
  inputValue: PropTypes.number,
  handleUpdateWallet: PropTypes.func,
  availablePoints: PropTypes.number,
};

PointsSlider.defaultProps = {
  sliderPoints: 0,
  availablePoints: 0,
  balance: 0,
};

export default PointsSlider;
