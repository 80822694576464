import React from 'react';
import PropTypes from 'prop-types';
import { Spacing } from '@reservamos/elements';
import 'styles/components/ExchangeLayout';
import Header from 'components/Header';
import BrandLogo from 'components/BrandLogo';

const ExchangeLayout = ({ children, sidebar }) => (
  <div className="l-exchange">
    <Header isFixed>
      <BrandLogo />
    </Header>
    <div className="exchange-container">
      <div className="exchange-content">
        <Spacing vertical size="XL">
          {children}
        </Spacing>
      </div>
      <div className="exchange-sidebar">
        <div className="exchange-sidebar-wrapper">{sidebar}</div>
      </div>
    </div>
  </div>
);

ExchangeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
};

export default ExchangeLayout;
