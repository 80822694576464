import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFeature } from '@growthbook/growthbook-react';
import { useHistory } from 'react-router-dom';
import { hasRoundTripDiscount, stopoverCount } from 'utils/Reserbus';
import DotersResultInvitation from 'components/Doters/DotersResultsInvitation';
import useGrowthBookFeatureValue from 'components/GrowthBookProvider/useGrowthBookFeatureValue';
import InfiniteScroll from 'components/InfiniteScroll';
import TripItem from 'components/search/TripItem';
import OpenTripItem from 'components/search/OpenTripItem';
import NoFilteredResults from 'components/search/NoFilteredResults';
import PoweredBy from 'components/PoweredBy';
import { Spacing, Text } from '@reservamos/elements';
import useFetchAd from '../../../hooks/useFetchAd';
import ResultsHorizontalLegend from '../ResultsHorizontalLegend';
import 'styles/components/search/SchedulesResults';
import PromotionalCard from '../../../ui/atoms/PromotionalCard';
import CostapassMessage from '../../../ui/atoms/CostaPassMessage';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import RewardTicketInvalidDialog from '../RewardTicketInvalidDialog';
import NoCategoriesFound from '../NoCategoriesFound';

const TripList = ({
  way,
  departureTrip,
  transportType,
  trips,
  paymentPlans,
  installmentsMinAmount,
  activeDepartureFilter,
  activeLocationFilter,
  tooManyFilters,
  resetTripFilters,
  selectTrip,
  isAdLoading,
  roundTrip,
  couponCode,
  discountedTripsByCoupon,
  isOpenTicketList,
  tripOpenTicket,
  isLogged,
  filtersApplied,
  isNotFiltered,
  showPoweredBy,
  showDotersBanner,
  highLightAsMobile,
  isHorizontal,
  passengersNotFound,
}) => {
  const [rewardTicketStatus, setRewardTicketStatus] = useState({
    status: '',
    onAccept: () => {},
  });
  const { features } = useSelector((state) => state.whitelabelConfig);
  const {
    ticket: { nit },
    operationNumbers,
  } = useSelector((state) => state.exchange);

  const [showNotCategoriesFound, setShowNotCategoriesFound] = useState(false);
  const passengersNotFoundAlert = useRef();

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const isExchange = queryParams.get('isExchange');

  const {
    costaPass: { userIsLoggedIn: userIsLoggedInWithCostaPass },
    tripsWithWalletDiscount,
    thereAreTripsWithWalletDiscount,
  } = useLoyaltyPrograms();
  const { t } = useTranslation();

  const isNewFilters = features.NEW_FILTERS_VERSION;

  const tripData = trips[0] || {
    origin: {
      cityId: '',
    },
    destination: {
      cityId: '',
    },
    providerId: '',
  };

  useFetchAd(
    {
      origin: tripData.origin ? tripData.origin.cityId : '',
      destination: tripData.destination ? tripData.destination.cityId : '',
      line: tripData.lineId || (tripData.carrier ? tripData.carrier.carrierId : ''),
    },
    'results',
    trips.length > 1,
  );

  const exchangeOpenTicketSelected = useRef();

  useEffect(() => {
    // Checks if user comes from exchange ticket
    // If operationNumbers & nit are undefined redirects the user to exchange
    if (isExchange && !operationNumbers && !nit) {
      history.push(`/exchange`);
    }
  }, [isExchange, operationNumbers, nit, history]);

  useEffect(() => {
    if (
      !exchangeOpenTicketSelected.current &&
      operationNumbers?.length &&
      nit &&
      trips.length === 1
    ) {
      exchangeOpenTicketSelected.current = true;
      selectTrip(trips[0], 0);
    }
  }, [operationNumbers?.length, nit, trips, selectTrip]);

  useEffect(() => {
    if (!isNotFiltered && passengersNotFound.length && !passengersNotFoundAlert.current) {
      passengersNotFoundAlert.current = true;
      setShowNotCategoriesFound(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengersNotFound]);

  let filterMessage = `${t('search:label.trips_filtered_by')} `;
  let filterContainer;
  let noResultsContainer;

  if (activeDepartureFilter && activeLocationFilter) {
    filterMessage += t('search:label.schedule_and_location');
  } else if (activeDepartureFilter) {
    filterMessage += t('search:label.schedule');
  } else if (activeLocationFilter) {
    filterMessage += t('search:label.location');
  }

  if (!isNewFilters && (activeDepartureFilter || activeLocationFilter)) {
    filterContainer = <p className="trip-message">{filterMessage}</p>;
  }

  if (tooManyFilters && !isNotFiltered) {
    noResultsContainer = <NoFilteredResults resetTripFilters={resetTripFilters} />;
  }

  const renderOpenTrips = () => {
    if (isNotFiltered) return null;

    const sortedTrips = trips.sort((a, b) => (a.service > b.service ? 1 : -1));
    let currentService = '';

    return sortedTrips.map((trip) => {
      let showServiceTag = false;
      const tripService = trip.service;

      if (currentService === tripService) {
        if (showServiceTag === true) showServiceTag = false;
      } else {
        currentService = tripService;
        showServiceTag = true;
      }

      return (
        <>
          {showServiceTag && (
            <Text color="grayMedium" size="M" fontFamily="secondary" weight="bold">
              {t('search:service', {
                service: t('purchase:services', { context: currentService }),
              })}
            </Text>
          )}

          <OpenTripItem
            key={trip.id}
            trip={trip}
            roundTrip={roundTrip}
            way={way}
            onSelectClick={selectTrip}
          />
        </>
      );
    });
  };

  /**
   * Closes the not categories found alert.
   */
  const handleOnCloseNotCategoriesFound = () => {
    setShowNotCategoriesFound(false);
  };

  /** Function to handle the click in the accept button in the not valid flat fare trip modal */
  const handleOnAcceptFlatFare = (onAccept) => () => {
    /**
     * @todo This method is probably not needed anymore, but it's here just in case
     */
    setRewardTicketStatus({
      status: 'continue',
      onAccept: () => {},
    });
    onAccept();
  };

  /** Function to handle the click in the cancel button in the not valid flat fare trip modal */
  const handleOnRejectFlatFare = () => {
    setRewardTicketStatus({ status: '', onAccept: () => {} });
  };

  const renderNormalTrips = () => {
    return trips.map((trip, index) => {
      let pricingObject = trip;
      let pricingKey = 'pricing';

      if (trip.isAd) {
        if (trips.length === 1 || (!isAdLoading && !trip.adData)) return null;

        const { body, imageUrl, link } = trip.adData || {};
        const adKey = `ad-${index}`;

        return (
          <PromotionalCard
            key={adKey}
            message={body}
            iconUrl={imageUrl}
            linkUrl={link}
            linkText="Ver mas"
            isLoading={isAdLoading}
          />
        );
      }

      if (roundTrip && way === 'departure') {
        if (trip.departureRoundTripPricing !== undefined) {
          pricingKey = 'departureRoundTripPricing';
        } else {
          pricingKey = 'pricing';
        }
      } else if (way === 'return') {
        if (features.VALIDATE_ROUND_TRIP_DISCOUNT) {
          if (hasRoundTripDiscount(departureTrip, trip)) {
            pricingKey = 'roundTripPricing';
          } else {
            pricingKey = 'pricing';
          }
        } else {
          pricingKey = 'roundTripPricing';
        }
      }

      const hasDiscountType = Boolean(trip[pricingKey].discount_type);

      const discountedTrips = thereAreTripsWithWalletDiscount
        ? tripsWithWalletDiscount
        : discountedTripsByCoupon;
      if (couponCode || thereAreTripsWithWalletDiscount) {
        const tripDiscounted = discountedTrips.find(({ id }) => id === trip.id) || trip;
        if (tripDiscounted && tripDiscounted[pricingKey]) pricingObject = tripDiscounted;
      }

      const providerDiscount = trip[pricingKey].providerDiscount || { amount: 0 };
      const { total } = pricingObject[pricingKey];
      const totalBeforeDiscount =
        trip[pricingKey].totalBeforeDiscount || providerDiscount.amount + total;

      const tripTransportType = features.PROVIDERS_SELECTION_ENABLED
        ? transportType
        : trip.transportType;

      const signMessagesInvitationCostapass = features.COSTAPASS_ENABLED &&
        !userIsLoggedInWithCostaPass && <CostapassMessage show />;

      return (
        <>
          <TripItem
            departureTrip={departureTrip}
            key={trip.id}
            trip={trip}
            paymentPlans={paymentPlans}
            hasDiscountType={hasDiscountType}
            hasRouteDetails={Boolean(trip.hasRouteDetails)}
            installmentsMinAmount={installmentsMinAmount}
            position={index + 1}
            type={trip.type || transportType}
            id={trip.id}
            origin={trip.origin}
            destination={trip.destination}
            departure={trip.departure}
            arrival={trip.arrival}
            duration={trip.duration}
            variableDepartureTime={trip.variableDepartureTime || false}
            providerDiscount={trip[pricingKey].providerDiscount}
            providerDiscounts={trip[pricingKey].providerDiscounts}
            totalBeforeDiscount={totalBeforeDiscount}
            total={pricingObject[pricingKey].total}
            serviceType={tripTransportType === 'bus' ? trip.line.serviceType : null}
            stops={stopoverCount(trip)}
            capacity={trip.capacity}
            availability={trip.availability}
            availableCategories={trip.passengerTypes}
            hasTransit={trip.hasTransit}
            stopoverPlace={trip.stopoverPlace}
            selectTrip={selectTrip}
            way={way}
            isRoundTrip={roundTrip}
            isOpenTicket={trip.isOpenTicket}
            isFromOpenTicketList={isOpenTicketList}
            supportWoman={trip.supportWoman}
            filtersApplied={filtersApplied}
            isLogged={isLogged}
            rewardTicketStatus={rewardTicketStatus}
            setRewardTicketStatus={setRewardTicketStatus}
            pricing={pricingObject[pricingKey]}
            highLightAsMobile={highLightAsMobile}
          />
          {!isNotFiltered && index === 6 && signMessagesInvitationCostapass}
        </>
      );
    });
  };

  const testOpenTicketPosition = useFeature('test-open-ticket-position');
  const isOpenTicketUnder = testOpenTicketPosition.value;
  const isNewResultsDesign = useGrowthBookFeatureValue('new_results_design');

  const openTicketResult =
    (!isNewResultsDesign && features.SHOW_OPEN_TICKET_ON_RESULTS && tripOpenTicket) || null;

  const openTicketButton =
    (isNewResultsDesign && features.SHOW_OPEN_TICKET_BUTTON && tripOpenTicket) || null;

  return (
    <>
      <div className="trip-container fade-in">
        {filterContainer}
        <Spacing size="M" vertical>
          {showNotCategoriesFound && (
            <NoCategoriesFound
              passengersNotFound={passengersNotFound}
              onClose={handleOnCloseNotCategoriesFound}
            />
          )}
          {noResultsContainer}

          {features.USE_HORIZONTAL_RESULTS && <ResultsHorizontalLegend />}

          {!isOpenTicketUnder && openTicketResult}

          <InfiniteScroll
            isHorizontal={isHorizontal}
            items={isOpenTicketList ? renderOpenTrips() : renderNormalTrips()}
            usesSideBar={features.SEARCH_SIDEBAR_ENABLED}
          />

          {isOpenTicketUnder && openTicketResult}
          {(showDotersBanner || openTicketButton) && (
            <Spacing
              isResponsive
              responsiveSize="medium"
              justifyContent={showDotersBanner ? 'space-between' : 'flex-end'}
              alignItems="flex-end"
              responsiveColumnReverse
            >
              {showDotersBanner && <DotersResultInvitation />}
              {openTicketButton}
            </Spacing>
          )}
        </Spacing>
        {!isNotFiltered && rewardTicketStatus.status === 'invalid' && (
          <RewardTicketInvalidDialog
            onAccept={handleOnAcceptFlatFare(rewardTicketStatus.onAccept)}
            onReject={handleOnRejectFlatFare}
          />
        )}
        {features.SHOW_POWERED_BY && showPoweredBy && <PoweredBy />}
      </div>
    </>
  );
};

TripList.propTypes = {
  way: PropTypes.string.isRequired,
  departureTrip: PropTypes.object,
  transportType: PropTypes.string,
  trips: PropTypes.array.isRequired,
  paymentPlans: PropTypes.objectOf(PropTypes.number).isRequired,
  installmentsMinAmount: PropTypes.number.isRequired,
  activeDepartureFilter: PropTypes.bool.isRequired,
  activeLocationFilter: PropTypes.bool.isRequired,
  tooManyFilters: PropTypes.bool.isRequired,
  resetTripFilters: PropTypes.func.isRequired,
  selectTrip: PropTypes.func.isRequired,
  isAdLoading: PropTypes.bool.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  couponCode: PropTypes.string,
  discountedTripsByCoupon: PropTypes.array,
  isOpenTicketList: PropTypes.bool,
  tripOpenTicket: PropTypes.any,
  isLogged: PropTypes.bool,
  filtersApplied: PropTypes.bool,
  isNotFiltered: PropTypes.bool,
  showPoweredBy: PropTypes.bool,
  showDotersBanner: PropTypes.bool,
  highLightAsMobile: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  passengersNotFound: PropTypes.array,
};

TripList.defaultProps = {
  passengersNotFound: [],
};

export default TripList;
