import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, MessageBox, LoaderPulse, Box } from '@reservamos/elements';
import DotersRewardsBalance from '../DotersRewardsBalance';

/**
 * Component that shows the points earned or a message that indicates that a purchase
 * doesn't generate points
 * @param {Object} props - Component props
 * @param {number} props.points - The current points to be earned
 * @param {number} props.pointsForNextLevel - Points needed to reach the next level
 * @param {number} props.pointsEarned - Points earned
 * @param {boolean} props.canGenerate - Flag to determine if points can be generated
 * @param {boolean} props.isLoading - Flag to determine if component is loading
 * @param {string} props.nextLevel - The name of the next level
 * @returns {JSX.Element} - Doters rewards balance component
 */
const DotersPointsEarned = ({
  useBox,
  points,
  pointsForNextLevel,
  pointsEarned,
  canGenerate,
  isLoading,
  nextLevel,
}) => {
  const { t } = useTranslation();

  if (isLoading) return <LoaderPulse size="S" isAccent />;

  if (!canGenerate || !points)
    return (
      <MessageBox borderColor="info" padding="S">
        <Text>{t('loyalty:doters_point_not_generate')}</Text>
      </MessageBox>
    );

  const component = (
    <DotersRewardsBalance
      points={points}
      pointsEarned={pointsEarned}
      nextLevelPoints={pointsForNextLevel}
      nextLevel={nextLevel}
    />
  );

  return useBox ? (
    <Box paddingHorizontalMobile="S" paddingVerticalMobile="S">
      {component}
    </Box>
  ) : (
    component
  );
};

DotersPointsEarned.propTypes = {
  useBox: PropTypes.bool,
  points: PropTypes.number.isRequired,
  pointsEarned: PropTypes.bool,
  canGenerate: PropTypes.bool,
  isLoading: PropTypes.bool,
  pointsForNextLevel: PropTypes.number.isRequired,
  nextLevel: PropTypes.string.isRequired,
};

DotersPointsEarned.defaultProps = {
  useBox: false,
  pointsEarned: false,
  canGenerate: false,
  isLoading: false,
};

export default DotersPointsEarned;
