/* eslint-disable import/prefer-default-export */
import { exchange as exchangeApi } from 'js-api-client';
import * as types from 'constants/ActionTypes';
import { setError } from '.';
import { createSchedulePath } from '../utils/exchange';
import customServerError from '../utils/purchase/customServerError';

/**
 * Function to validate the exchange
 * @param {*} history - History object
 * @param {*} operationNumbers - Operation numbers
 * @param {*} NIT - NIT number
 * @param {*} origin - Origin slug
 * @param {*} destination - Destination slug
 * @param {*} recaptchaToken - Recaptcha token
 * @returns {function} - Dispatch function
 */
export function validateExchange(
  history,
  operationNumbers,
  NIT,
  origin,
  destination,
  recaptchaToken,
) {
  return (dispatch) => {
    dispatch({ type: types.EXCHANGE_SET_LOADING, isLoading: true });
    return exchangeApi
      .validate(operationNumbers, NIT, origin, destination, recaptchaToken)
      .then((response) => {
        const { error } = response;
        if (error && error.message) {
          dispatch(setError(200, 'exchange_cannot_be_used', 'warning', false, error.message));
        } else if (Object.keys(response).length === 0) {
          dispatch(setError(200, 'exchange_cannot_be_used', 'warning', false));
          history.push('/exchange');
        } else {
          dispatch({ type: types.EXCHANGE_RECEIVE, data: response, nit: NIT });
          history.push(createSchedulePath(operationNumbers, NIT, origin, destination));
        }
      })
      .catch((error) => {
        const { code, message } = error;
        dispatch(
          setError(code, 'exchange_not_found', 'warning', false, customServerError(code, message)),
        );
        history.replace('/exchange');
      })
      .finally(() => {
        dispatch({ type: types.EXCHANGE_SET_LOADING, isLoading: false });
      });
  };
}

export function errorTripExchange(errorTrip) {
  return { type: types.ERROR_TRIP_EXCHANGE, errorTrip };
}

export function resetExchange() {
  return { type: types.EXCHANGE_RESET };
}
