import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'styles/components/TripResult';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDuration } from 'utils/Helpers';
import {
  Text,
  Button,
  FlatButton,
  ResultProvider,
  Spacing,
  OutlineButton,
  Icon,
} from '@reservamos/elements';
import { getCurrencyDecimals, getCurrencySuffix } from 'utils/currency';
import TripSchedule from 'ui/atoms/TripSchedule';
import TimeIcon from 'ui/atoms/TimeIcon';
import useWhitelabelTheme from 'hooks/whitelabel/useWhitelabelTheme';
import { isIAMSA } from 'utils/Reserbus';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import PreviousPrice from 'ui/atoms/PreviousPrice';
import { isSeatsOnResultActivated } from 'utils/seats';
import petFriendly from '../../images/icons/footPrint.svg';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import '../../ui/layouts/MatrixResult';
import TripFiltersBadges from './TripFiltersBadges';
import NewPriceComponent from './NewPriceComponent';
import TripDiscountAdvice from './TripDiscountAdvice';
import ResultCategoriesAvailability from './ResultCategoriesAvailability';
import WalletPrice from './WalletPrice';

/**
 * This result is used by:
 * - IAMSA (ETN, Costaline, Autovias)
 * - Expreso Brasilia
 * - Smilebus
 */

// hide the duration if is minor than 16min
// because this is probably wrong estimation
const MIN_DURATION = 15;
/**
 * Render the card of a trip on the search results
 * @param {Object} props
 * @param {String} props.id - Trip Id.
 * @param {string} props.arrival - Arrival time of the trip
 * @param {string} props.departure - Departure time of the trip
 * @param {number} props.duration - Duration of the trip in minutes
 * @param {function} props.onSelectClick - Function to handle the selection of a trip
 * @param {Object} props.providerDiscount - Provider discount information
 * @param {number} props.total - Total price of the trip
 * @param {Object} props.trip - Trip information
 * @param {boolean} props.isExchange - Flag indicating if it is an exchange trip
 * @param {function} props.handleShowTripDetails - Function to handle showing trip details
 * @param {boolean} props.highLightType - Flag indicating if the trip is highlighted
 * @param {string} props.highlightText - Text to highlight
 * @param {Array} props.providerDiscounts - Array of provider discounts
 * @param {string} props.useFlatFare - Show use flat fare
 * @param {number} props.walletPoints - Total price in wallet points
 * @param {Object} props.filtersApplied - Applied filters
 * @param {string} props.serviceType - Type of service
 * @param {string} props.way - Trip way
 * @param {number} props.showWalletPoints - Number of wallet points to show
 * @param {Object} props.pricing - Pricing information
 * @param {string} props.walletType - Wallet type
 * @param {boolean} props.highLightAsMobile - Flag indicating if the trip is highlighted as mobile
 * @param {Array} props.availableCategories - The available categories for the trip.
 * @param {boolean} props.showNewFiltersList - Flag indicating if the new filters list should be shown
 * @param {boolean} props.discountBase - Flag indicating if the discount is base
 * @param {number} props.totalBeforeDiscount - Total price before discount
 * @param {boolean} props.isOpen - Flag indicating if the trip is open
 * @param {node} props.children - Children nodes
 * @param {node} props.recentView - Recent view node
 * @returns {React.Component} - ResultSpecifics component
 */
const TripResult = ({
  arrival,
  departure,
  duration,
  onSelectClick,
  providerDiscount,
  total,
  trip,
  isExchange,
  totalBase,
  handleShowTripDetails,
  highLightType,
  highlightText,
  providerDiscounts,
  id,
  useFlatFare,
  walletPoints,
  filtersApplied,
  way,
  showWalletPoints,
  pricing,
  walletType,
  highLightAsMobile,
  serviceType,
  showNewFiltersList,
  availableCategories,
  discountBase,
  totalBeforeDiscount,
  isOpen,
  children,
  recentView,
}) => {
  const { theme, brand } = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();
  const { getServiceType, serviceTypes, resultsCTAColor } = useWhitelabelTheme();
  const {
    passengerTypes,
    stops,
    variableDepartureTime,
    line,
    secondFloor,
    isPetFriendly,
    service,
  } = trip;

  const [showDetailsModal, setShowDetailsModal] = useState(true);
  const { t } = useTranslation();

  const showDuration = duration > MIN_DURATION;
  const showFlatFare = useFlatFare === 'valid';
  // eslint-disable-next-line
  const ctaText = isSeatsOnResultActivated() ? t('general:view_seats') : t('general:select');
  const buttonText = isOpen ? t('general:close') : ctaText;
  const colorPrice = providerDiscount ? 'discount' : 'grayMedium';
  const departureHour = moment(departure).format('LT');
  const arrivalHour = moment(arrival).format('LT');
  const DOTERS_CURRENCY = t('search:doters_currency');

  /**
   * Prices
   */
  const primaryCurrency = showFlatFare ? DOTERS_CURRENCY : getCurrencySuffix();
  let primaryPrice = total;
  if (showFlatFare) primaryPrice = walletPoints;
  if (totalBase && !showFlatFare) primaryPrice = totalBase;
  const currencyDoters = primaryCurrency === DOTERS_CURRENCY;

  const earlyDiscount = pricing.discountType === 'early_trip_discount';

  const stopsText = t('search:trip_stopovers', {
    count: stops,
    context: stops === 0 ? 'direct' : null,
  });

  /**
   * Selects the appropriate service label based on the feature flags and theme settings.
   * @returns {string} The service label.
   */
  const selectServiceLabel = () => {
    if (features.SHOW_TRIP_SERVICE_LABEL) {
      return service;
    }

    const { useLineNameAsServiceType = false } = theme.serviceTypes || {};
    const serviceProp = serviceTypes ? service : serviceType;
    return useLineNameAsServiceType ? line.name : getServiceType(serviceProp).label;
  };

  const serviceTypeLabel = selectServiceLabel();
  const hasProviderDiscount = providerDiscount && Boolean(providerDiscount.amount);
  const showPriceWithoutDiscount = hasProviderDiscount && features.SHOW_OFFICE_PRICE;

  /**
   * Method to handle the selection of a trip
   * @param {String} funnelSection - section of the funnel where the trip was selected
   */
  const handleOnSelect = (funnelSection) => {
    const funnelSectionSelected = funnelSection === 'details' ? 'details' : 'results';
    onSelectClick(funnelSectionSelected);
    if (isExchange) setShowDetailsModal(false);
  };

  const contentTypes = [
    <Text size="XS" weight="regular" color="grayLight" textAlign="center">
      {stopsText}
    </Text>,
  ];
  if (features.SHOW_VARIABLE_DEPARTURE_TIME) {
    contentTypes.push(
      <Text size="XS" weight="regular" color="grayLight" textAlign="center">
        {variableDepartureTime
          ? `${t('search:trip_service_on_the_way')}`
          : `${t('search:trip_service_local')}`}
      </Text>,
    );
  }

  const detailsComponent = showDetailsModal ? (
    <Spacing>
      <ProviderInfo
        amenities={line.services}
        providerDiscounts={providerDiscounts}
        initialTab={0}
        availabilityCategories={passengerTypes}
        tripId={id}
        earlyDiscount={earlyDiscount}
        buttonText={buttonText}
        onSelectClick={handleOnSelect}
        departure={departureHour}
        arrival={arrivalHour}
        price={!isExchange && primaryPrice}
        colorPrice={colorPrice}
        currency={primaryCurrency}
        decimals={currencyDoters ? 0 : getCurrencyDecimals()}
        sign={currencyDoters ? '' : '$'}
        currencyLowercase={showFlatFare}
        showFlatFare={showFlatFare}
        isOnTheWay={variableDepartureTime}
      >
        <div className="matrix-mobile-click">
          <FlatButton size="S" onClick={handleShowTripDetails}>
            {t('general:show_details_new')}
          </FlatButton>
        </div>
      </ProviderInfo>
      {showNewFiltersList ? (
        <TripFiltersBadges filtersApplied={filtersApplied} trip={trip} minimalDesign />
      ) : (
        <ResultCategoriesAvailability availableCategories={availableCategories} />
      )}
    </Spacing>
  ) : (
    <></>
  );

  const hidePriceOnExchange = features.HIDE_PRICE_ON_EXCHANGE && isExchange;
  const isIAMSABrand = isIAMSA(brand);
  const relocateDesktopServiceType = brand === 'expressosaoluiz';
  const hasDiscount =
    isIAMSABrand && Boolean(discountBase !== undefined ? discountBase : providerDiscount);
  const floorsLabel =
    features.HAS_MULTIPLE_FLOORS_TRIPS && secondFloor
      ? t('search:double_floor_new')
      : t('search:one_floor_new');

  return (
    <ResultProvider
      brand={features.SHOW_RESULT_LINE_NAME ? line.name : ''}
      logoUrl={line.logoUrl}
      iconDeparture={<TimeIcon time={departure} />}
      serviceType={
        isIAMSABrand ? floorsLabel : features.SHOW_SERVICE_TYPE_BADGE && serviceTypeLabel
      }
      relocateDesktopServiceType={relocateDesktopServiceType}
      duration={
        showDuration && (
          <Text size="XS" mediumSize="M" color="grayMedium">
            {formatDuration(duration)}
          </Text>
        )
      }
      discount={
        !hidePriceOnExchange ? (
          <TripDiscountAdvice
            discountType={pricing.discountType}
            discountAvailability={pricing.discountAvailability}
            discountAmount={providerDiscount?.amount}
            discountPercent={providerDiscount?.percent}
            tripTotal={total}
            way={way}
          />
        ) : null
      }
      schedule={
        <Spacing size="XS" alignItems="center">
          <Spacing size="L" alignItems="center">
            <TripSchedule
              departureHour={departureHour}
              arrivalHour={arrivalHour}
              mainContent={contentTypes}
              mobileSize="L"
            />
          </Spacing>
          {isIAMSABrand && isPetFriendly && <Icon size="S" type={petFriendly} />}
        </Spacing>
      }
      details={detailsComponent}
      button={
        highLightType ? (
          <Button
            text={buttonText}
            onClick={handleOnSelect}
            padding="L"
            size="M"
            weight="semibold"
            variant={resultsCTAColor}
            isRounded
          />
        ) : (
          <OutlineButton
            isRounded
            variant={resultsCTAColor}
            text={buttonText}
            onClick={handleOnSelect}
          />
        )
      }
      price={
        !hidePriceOnExchange ? (
          <NewPriceComponent
            total={total}
            walletTotal={walletPoints}
            highLightType={highLightType}
            walletType={walletType}
            showWalletTotal={showWalletPoints}
            extraWalletText={
              showFlatFare &&
              t('purchase:label.reward_ticket', { context: features.FLAT_FARE_CONTEXT })
            }
            useDiscountCurrency={hasDiscount}
            secondaryPrice={
              isIAMSABrand ? (
                <Spacing size="XS" alignItems="center">
                  {showFlatFare && (
                    <Text size="XS" weight="semibold" italic>
                      {t('purchase:label.reward')}
                    </Text>
                  )}
                  <WalletPrice
                    walletType="doters"
                    walletTotal={walletPoints}
                    size="S"
                    mobileSize="M"
                    iconSize="XS"
                  />
                </Spacing>
              ) : (
                (showPriceWithoutDiscount && <PreviousPrice price={totalBeforeDiscount} />) || null
              )
            }
            lighterText={isOpen}
          />
        ) : null
      }
      changeLayout={features.CHANGE_RESULT_LAYOUT}
      highlight={highLightType}
      highlightText={highlightText}
      mobileLayout={highLightAsMobile}
      highlightMinHeight={highLightAsMobile}
      isOpen={isOpen}
      recentView={recentView}
      recentViewText={t('trips:recently_seen')}
    >
      {children}
    </ResultProvider>
  );
};

const placeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
});

TripResult.propTypes = {
  arrival: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
  destination: placeType.isRequired,
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  origin: placeType.isRequired,
  providerDiscount: PropTypes.any,
  total: PropTypes.number.isRequired,
  totalBeforeDiscount: PropTypes.number,
  trip: PropTypes.shape({
    line: PropTypes.shape({
      logoUrl: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.string).isRequired,
      name: PropTypes.string,
    }).isRequired,
    pricing: PropTypes.shape({
      discountType: PropTypes.string,
      discountAvailability: PropTypes.number,
      totalBeforeDiscount: PropTypes.number,
    }),
    passengerTypes: PropTypes.array,
    variableDepartureTime: PropTypes.bool,
    secondFloor: PropTypes.bool,
    stops: PropTypes.number,
    service: PropTypes.string,
    isPetFriendly: PropTypes.bool,
  }).isRequired,
  highLightType: PropTypes.bool,
  isExchange: PropTypes.bool,
  handleShowTripDetails: PropTypes.func,
  providerDiscounts: PropTypes.array,
  useFlatFare: PropTypes.string,
  walletPoints: PropTypes.number,
  totalBase: PropTypes.number,
  filtersApplied: PropTypes.object,
  secondFloor: PropTypes.bool,
  way: PropTypes.string,
  showWalletPoints: PropTypes.number,
  pricing: PropTypes.shape({
    discountType: PropTypes.string,
    discountAvailability: PropTypes.number,
  }),
  walletType: PropTypes.string,
  highLightAsMobile: PropTypes.bool,
  serviceType: PropTypes.string,
  showNewFiltersList: PropTypes.bool,
  availableCategories: PropTypes.array,
  discountBase: PropTypes.bool,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  recentView: PropTypes.node,
  highlightText: PropTypes.string,
};

TripResult.defaultProps = {
  providerDiscount: null,
  providerDiscounts: [],
};

export default TripResult;
