import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useScript from '../../hooks/useScript';

const propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

const openPayScripts = {
  pe: {
    openPayJs: 'https://js.openpay.pe/openpay.v1.min.js',
    antiFraud: 'https://js.openpay.pe/openpay-data.v1.min.js',
  },
  default: {},
};

const OpenPayLoader = ({ children, config }) => {
  const { enabled, id, publicKey, isSandbox, country } = config;
  const { openPayJs, antiFraud } = openPayScripts[country] || openPayScripts.default;
  const { isLoaded: openPayJsScriptIsLoaded } = useScript(openPayJs, {
    shouldLoadScript: enabled,
  });
  const { isLoaded: antiFraudScriptIsLoaded } = useScript(antiFraud, {
    shouldLoadScript: openPayJsScriptIsLoaded,
  });

  useEffect(() => {
    if (
      enabled &&
      id &&
      publicKey &&
      openPayJsScriptIsLoaded &&
      antiFraudScriptIsLoaded &&
      window.OpenPay
    ) {

      window.OpenPay.setId(id);
      window.OpenPay.setApiKey(publicKey);
      window.OpenPay.setSandboxMode(isSandbox);

    } else {


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPayJsScriptIsLoaded, antiFraudScriptIsLoaded]);

  return <>{children}</>;
};

OpenPayLoader.propTypes = propTypes;

export default OpenPayLoader;
