import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Currency, Icon, Text } from '@reservamos/elements';
import { getCurrencySuffix, getCurrencyDecimals, getCurrencyPrefix } from 'utils/currency';
import './DayButton.scss';

const DayButton = ({ onClick, leftIcon, date, rightIcon, price, disabled, hasRadius }) => {
  const { t } = useTranslation('general');

  let textcolor = 'grayMedium';

  if (disabled) {
    textcolor = 'grayLight';
  }

  return (
    <button
      onClick={onClick}
      className={`rounded-button ${disabled ? 'rounded-button-disabled' : ''} ${
        hasRadius ? 'rounded-button-radius' : ''
      }`}
      disabled={disabled}
    >
      {leftIcon && (
        <Icon type="CircleArrowLeft" hasMargin color={disabled ? 'grayLight' : 'primary'} />
      )}
      <span className="rounded-button-wrapper">
        <Text size="XS" color={textcolor} weight="bold" elementType="span">
          {date}
        </Text>
        {Boolean(price) && (
          <span className="rounded-button-price">
            <Text size="XS" color={textcolor} elementType="span">
              {t('from')}
            </Text>
            <Currency
              price={price}
              decimals={getCurrencyDecimals()}
              color={textcolor}
              sign={getCurrencyPrefix()}
              currency={getCurrencySuffix()}
              weight="bold"
              size="S"
            />
          </span>
        )}
      </span>
      {rightIcon && (
        <Icon
          type="CircleArrowRight"
          hasMargin
          marginSide="left"
          color={disabled ? 'grayLight' : 'primary'}
        />
      )}
    </button>
  );
};

DayButton.propTypes = {
  date: PropTypes.string,
  leftIcon: PropTypes.bool,
  rightIcon: PropTypes.bool,
  price: PropTypes.number,
  disabled: PropTypes.bool,
  hasRadius: PropTypes.bool,
  onClick: PropTypes.func,
};

DayButton.defaultProps = {
  date: '',
  leftIcon: false,
  disabled: false,
  rightIcon: false,
  hasRadius: false,
  price: null,
  onClick: () => {},
};

export default DayButton;
