import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { resultsActionsTracker, sortTracker, filterTracker } from 'metrics/user-analytics/search';
import pickedProviderTracker from 'metrics/user-analytics/pickedProviderTracker';
import Modal from 'components/Modal';
import TransportFilter from 'components/search/TransportFilter';
import ProviderList from 'components/search/ProviderList';
import ProviderViewOptions from 'components/search/ProviderViewOptions';
import ProviderSidebar from 'components/ProviderSidebar';
import { Button, Spacing } from '@reservamos/elements';
import NoResults from '../../../ui/molecules/NoResults';
import OpenTicketWrapper from '../OpenTicketWrapper';
import OpenTicketButton from '../OpenTicketButton';
import DayControls from '../DayControls';
import 'styles/components/search/ProvidersResults';
import ResultsTitle from '../../../ui/atoms/ResultsTitle';
import { cleanAnalyticsParams } from '../../../utils/urls';

const propTypes = {
  origin: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  passengers: PropTypes.string.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  way: PropTypes.string.isRequired,
  departureId: PropTypes.string,
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  adAttribution: PropTypes.string,
  installmentsMinAmount: PropTypes.number.isRequired,
  providers: PropTypes.array.isRequired,
  noResults: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  filterBy: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  sortProvidersBy: PropTypes.func.isRequired,
  filterProvidersBy: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  uniqueLine: PropTypes.string.isRequired,
  openTrip: PropTypes.object,
  t: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

class Providers extends Component {
  constructor(props) {
    super(props);

    this.optionsClick = this.optionsClick.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.setFilterby = this.setFilterby.bind(this);
    this.selectProvider = this.selectProvider.bind(this);
    this.buyDepartureTrip = this.buyDepartureTrip.bind(this);
    this.selectOpenTrip = this.selectOpenTrip.bind(this);
  }

  componentDidMount() {
    const { currentUrl, skip, uniqueLine, way, features } = this.props;
    const routeSegments = currentUrl.split('/');
    cleanAnalyticsParams();

    if (!features.PROVIDERS_SELECTION_ENABLED) {
      routeSegments.pop();
      routeSegments.push('departures');
      skip(routeSegments.join('/'));
      return;
    }

    if (uniqueLine) {
      const routeSegments = currentUrl.split('/');
      if (way === 'departure') {
        routeSegments.pop();

        if (!routeSegments.includes('departures')) {
          routeSegments.push('departures');
        }

        routeSegments.push(uniqueLine);
      } else {
        routeSegments.splice(-2);
        routeSegments.push('returns', uniqueLine);
      }
      skip(routeSegments.join('/'));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUrl, uniqueLine, skip, way } = this.props;

    if (prevProps.uniqueLine !== uniqueLine && uniqueLine) {
      let routeSegments = currentUrl.split('/');
      if (way === 'departure') {
        routeSegments.pop();

        if (!routeSegments.includes('departures')) {
          routeSegments.push('departures');
        }

        routeSegments.push(uniqueLine);
      } else {
        routeSegments = routeSegments.slice(0, -2);
        routeSegments.push('returns', uniqueLine);
      }
      skip(routeSegments.join('/'));
    }
  }

  getAnalyticsData() {
    const { origin, destination, roundTrip, departureDate, returnDate } = this.props;

    return {
      origin,
      destination,
      roundTrip,
      departureDate: moment(departureDate, 'DD-MMM-YY').toDate(),
      returnDate: moment(returnDate, 'DD-MMM-YY').toDate(),
    };
  }

  setSortOrder(sortBy) {
    const { sortProvidersBy } = this.props;

    sortProvidersBy(sortBy);
    sortTracker(this.getAnalyticsData(), sortBy, 'Providers');
  }

  /**
   * Applied several filters to the search
   * @param {Object} filterBy - Filter applied
   * @param {*} isFiltersUpdating - Indicates if it is an update of filters but not made by the user
   */
  setFilterby(filterBy, isFiltersUpdating) {
    const { filterProvidersBy } = this.props;
    const filterByToUse = typeof filterBy === 'string' ? filterBy : [...filterBy.departureTime];

    filterProvidersBy(filterByToUse);
    if (!isFiltersUpdating) filterTracker(this.getAnalyticsData(), filterByToUse, 'Providers');
  }

  optionsClick(modalComponent, type) {
    const { showModal } = this.props;

    showModal(modalComponent, type);
    resultsActionsTracker(this.getAnalyticsData(), 'Sort', 'Providers');
  }

  selectProvider(provider, activeAd) {
    const {
      origin,
      destination,
      passengers,
      roundTrip,
      departureDate,
      returnDate,
      way,
      departureId,
      next,
    } = this.props;
    let route = `/search/${origin.id}/${destination.id}/${departureDate}`;

    if (roundTrip) route += `/${returnDate}`;

    if (way === 'departure') {
      route += `/p/${passengers}/departures/${provider.id}`;
    } else {
      route += `/p/${passengers}/${departureId}/returns/${provider.id}`;
    }

    pickedProviderTracker(this.getAnalyticsData(), provider);
    next(route, { adAttribution: activeAd });
  }

  buyDepartureTrip() {
    const { departureId, passengers, next, adAttribution } = this.props;

    next(`/purchase/${departureId}/new/${passengers}`, { adAttribution });
  }

  selectOpenTrip(trip) {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      roundTrip,
      departureId,
      passengers,
      way,
      next,
    } = this.props;

    const queryParams = { seenPrice: trip.pricing.total, providerId: trip.providerId };

    if (way === 'departure') {
      const route =
        `/search/${origin.id}/${destination.id}/${departureDate}` +
        `${roundTrip ? `/${returnDate}` : ''}` +
        `/p/${passengers}/departures/open`;

      next(route, queryParams);
    } else {
      const route =
        `/search/${origin.id}/${destination.id}/${departureDate}/${returnDate}` +
        `/p/${passengers}/${departureId}/returns/open`;

      next(route, queryParams);
    }
  }

  renderBuyDepartureButton() {
    const { way, providers, noResults, t } = this.props;

    if ((providers.length > 0 || noResults) && way === 'return') {
      return (
        <Spacing alignItems="flex-start" fullWidth>
          <Button
            onClick={this.buyDepartureTrip}
            text={t('purchase:button.buy_one_way_trip')}
            variant="accent"
            isRounded
            isRoundedOnSmall
            fullWidthOnSmall
            minHeight="40px"
          />
        </Spacing>
      );
    }

    return null;
  }

  render() {
    const {
      roundTrip,
      way,
      providers,
      noResults,
      isLoading,
      sortBy,
      filterBy,
      installmentsMinAmount,
      showModal,
      openTrip,
      features,
      resetFilters,
    } = this.props;

    let openTicketContent;
    if (features.SHOW_OPEN_TICKET_ON_PROVIDERS && openTrip) {
      const pricingKey = way === 'departure' ? 'pricing' : 'roundTripPricing';
      openTicketContent = (
        <OpenTicketWrapper>
          <OpenTicketButton
            selectTrip={this.selectOpenTrip}
            trip={openTrip}
            providerDiscount={openTrip[pricingKey].providerDiscount}
            total={openTrip[pricingKey].total}
          />
        </OpenTicketWrapper>
      );
    }

    return (
      <div className="providers-container">
        {noResults ? (
          <div className="not-found-container">
            <Spacing vertical>
              <NoResults way={way} isProviderList />
              {this.renderBuyDepartureButton()}
            </Spacing>
          </div>
        ) : (
          <>
            <ResultsTitle
              sectionTitle={way === 'return' ? 'providersReturn' : 'providersDeparture'}
              rightContent={openTicketContent}
              hideTitleOnMobile
            />

            {features.SEARCH_SIDEBAR_ENABLED && providers.length > 0 && (
              <ProviderSidebar
                onChangeSort={this.setSortOrder}
                onChangeFilter={this.setFilterby}
                sort={sortBy}
                filter={filterBy}
                way={way}
                onResetFilters={resetFilters}
              />
            )}

            {!features.SEARCH_SIDEBAR_ENABLED && (
              <div className="result-options">
                <TransportFilter hasProviders={!!providers.length} isLoading={isLoading} />

                {providers.length > 1 ? (
                  <ProviderViewOptions onSortClick={this.optionsClick} sortProperty={sortBy} />
                ) : null}

                <Modal onSortSelect={this.setSortOrder} providerSortProperty={sortBy} />
              </div>
            )}

            <div className="results-container">
              <Spacing vertical size="S">
                <ProviderList
                  providers={providers}
                  roundTrip={roundTrip}
                  sortBy={sortBy}
                  filterBy={filterBy}
                  way={way}
                  installmentsMinAmount={installmentsMinAmount}
                  onSelectProvider={this.selectProvider}
                  onDisclaimerClick={showModal}
                  isLoading={isLoading}
                  showPoweredBy
                />

                <div className="results-container-no-filters">
                  <ProviderList
                    providers={providers}
                    roundTrip={roundTrip}
                    sortBy={sortBy}
                    filterBy={filterBy}
                    way={way}
                    installmentsMinAmount={installmentsMinAmount}
                    onSelectProvider={this.selectProvider}
                    onDisclaimerClick={showModal}
                    isLoading={isLoading}
                    isNotFiltered
                  />
                </div>
                {this.renderBuyDepartureButton()}
                {features.SHOW_MIN_PRICES && providers.length > 1 && (
                  <DayControls way={way} isProviderList fixed />
                )}
              </Spacing>
            </div>
          </>
        )}
      </div>
    );
  }
}

Providers.propTypes = propTypes;

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
});

export default connect(mapStateToProps)(withTranslation('search')(Providers));
