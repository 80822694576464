import { camelizeKeys } from 'humps';
import { omit } from 'lodash';
import { hasMultipleCarriers, getPlaceType } from 'utils/Reserbus';
import camelizeConverter from 'utils/camelizeConverter';

function getTransportType(fragments) {
  if (fragments.length > 1) {
    return 'mixed';
  }

  return fragments[0].transportType;
}

function parseReservation(reservation, collections) {
  const { terminals, airports, lines, carriers, cities, fareServices } = collections;

  // rename "oneWayPurchases" property as "fragments"
  reservation.fragments = reservation.oneWayPurchases;
  Reflect.deleteProperty(reservation, 'oneWayPurchases');

  reservation.fragments.forEach((fragment) => {
    // merge the reservation trip object into the reservation
    // so it matches the itinerary structure
    Object.assign(fragment, { ...fragment.trip });

    if (fragment.trip.transportType === 'flight') {
      fragment.origin = airports[fragment.originId];
      fragment.origin.countryName = cities[fragment.origin.cityId].country;
      fragment.destination = airports[fragment.destinationId];
      fragment.destination.countryName = cities[fragment.destination.cityId].country;
      fragment.carrier = carriers[fragment.legs[0].carrierId];
      fragment.services = fareServices[fragment.fareService];
      fragment.legs.forEach((leg) => {
        leg.transportType = 'flight';
        leg.origin = airports[leg.originId];
        leg.destination = airports[leg.destinationId];
        leg.carrier = carriers[leg.carrierId];
      });
    } else {
      fragment.origin = terminals[fragment.originId];
      fragment.origin.countryName = cities[fragment.origin.cityId].country;
      fragment.destination = terminals[fragment.destinationId];
      fragment.destination.countryName = cities[fragment.destination.cityId].country;
      fragment.line = lines[fragment.lineId];
    }
  });

  const tickets = [];
  reservation.fragments.forEach((fragment) => tickets.push(...fragment.tickets));
  reservation.tickets = tickets;
  reservation.origin = reservation.fragments[0].origin;
  reservation.destination = reservation.fragments[reservation.fragments.length - 1].destination;
  reservation.transportType = getTransportType(reservation.fragments);

  if (reservation.transportType === 'mixed') {
    const [firstFragment, secondFragment] = reservation.fragments;

    reservation.stopoverPlace = {
      type: getPlaceType(secondFragment.origin.id),
      hasTransit: !!firstFragment.connectionIds.length,
      sameLine: reservation.fragments.every(
        ({ providerId }) => providerId === firstFragment.providerId,
      ),
      ...secondFragment.origin,
    };
  } else if (reservation.transportType === 'flight') {
    reservation.stopoverPlace = {
      type: 'airport',
      hasTransit: false,
      sameLine: !hasMultipleCarriers(reservation.fragments[0].legs),
    };
  }
}

export default function parsePurchase(purchaseResponse) {
  const camelizedResponse = camelizeKeys(purchaseResponse, camelizeConverter);

  const {
    purchase,
    purchase: { departs, returns },
  } = camelizedResponse;
  const collections = omit(camelizedResponse, 'purchase');

  purchase.roundTrip = !!returns;
  parseReservation(departs, collections);

  if (returns) {
    parseReservation(returns, collections);
  }

  return purchase;
}
