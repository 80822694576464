import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SeatSelector from 'components/purchase/SeatSelector';
import { exchangeSeatChangeTracker, seatChangeTracker } from 'metrics/user-analytics/purchase';

const propTypes = {
  purchase: PropTypes.shape({
    isExchange: PropTypes.bool.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    isFetchingDetails: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    departs: PropTypes.object.isRequired,
    returns: PropTypes.object,
    allowsSeatSelection: PropTypes.bool.isRequired,
    passengers: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        busCategory: PropTypes.string.isRequired,
      }),
    ).isRequired,
    busCategories: PropTypes.array,
    isUpdating: PropTypes.bool.isRequired,
    hasConnections: PropTypes.bool.isRequired,
    roundTrip: PropTypes.bool.isRequired,
  }),
  fetchTripsDetails: PropTypes.func.isRequired,
  selectSeats: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  resetPassengers: PropTypes.func.isRequired,
  finishSeatSelection: PropTypes.func.isRequired,
  updateSeat: PropTypes.func.isRequired,
  departureFragments: PropTypes.array.isRequired,
  returnFragments: PropTypes.array,
  hasDetails: PropTypes.bool.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  allowsSeatSelection: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      busCategory: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isExchange: PropTypes.bool.isRequired,
  busCategories: PropTypes.array,
  lockedSeats: PropTypes.array,
  seats: PropTypes.array,
  diagramType: PropTypes.string,
  layout: PropTypes.object,
  isLockingTickets: PropTypes.bool,
  fetchingDetails: PropTypes.bool,
  selectedSeats: PropTypes.array,
  isOpenTicket: PropTypes.bool,
  minPassengers: PropTypes.number,
  canGoBack: PropTypes.bool,
  canResetPassengers: PropTypes.bool,
  isPetFriendlyTrip: PropTypes.bool,
  isPurchaseView: PropTypes.bool,
  tripPricing: PropTypes.object,
  hasSeats: PropTypes.bool,
  tripId: PropTypes.string,
  firstFloorReclination: PropTypes.string,
  secondFloorReclination: PropTypes.string,
  resultsLayout: PropTypes.bool,
  onClose: PropTypes.func,
  trip: PropTypes.object,
  clearError: PropTypes.func,
  fetchingDetailsError: PropTypes.bool,
  passengerTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }),
  ),
  refreshingBus: PropTypes.bool,
};

class DepartureSeats extends Component {
  constructor(props) {
    super(props);

    this.finishedSeatSelection = this.finishedSeatSelection.bind(this);
  }

  componentDidMount() {
    const { resetPassengers, allowsSeatSelection, passengers, canResetPassengers, isPurchaseView } =
      this.props;

    this.getReservationDetails();
    if (!isPurchaseView) return;
    if (canResetPassengers) {
      resetPassengers(passengers);
    }

    if (!allowsSeatSelection) {
      this.finishedSeatSelection();
    }
  }

  getReservationDetails() {
    const {
      fetchTripsDetails,
      departureFragments,
      returnFragments,
      hasDetails,
      isFetchingDetails,
    } = this.props;

    if (!(hasDetails || isFetchingDetails)) {
      fetchTripsDetails({
        departureFragments,
        returnFragments,
      });
    }
  }

  finishedSeatSelection() {
    const { finishSeatSelection, purchase, resultsLayout, isExchange } = this.props;

    if (isExchange) {
      exchangeSeatChangeTracker(purchase, 'departure');
    } else {
      seatChangeTracker(purchase, 'departure', resultsLayout ? 'results' : 'purchase');
    }

    finishSeatSelection();
  }

  render() {
    const {
      selectSeats,
      sendNotification,
      updateSeat,
      isExchange,
      busCategories,
      isLoading,
      allowsSeatSelection,
      passengers,
      lockedSeats,
      seats,
      diagramType,
      layout,
      isLockingTickets = false,
      fetchingDetails = true,
      selectedSeats = [],
      isOpenTicket,
      minPassengers,
      canGoBack,
      isPetFriendlyTrip,
      tripPricing,
      hasSeats,
      tripId,
      resultsLayout,
      onClose,
      trip,
      clearError,
      fetchingDetailsError,
      passengerTypes,
      firstFloorReclination,
      secondFloorReclination,
      refreshingBus,
      purchase,
    } = this.props;

    const loading =
      !allowsSeatSelection || isLockingTickets || !hasSeats || isLoading || refreshingBus;

    return (
      <SeatSelector
        tripId={tripId}
        allowsSeatSelection={allowsSeatSelection}
        diagramType={diagramType}
        way="departure"
        fetchingDetails={fetchingDetails}
        isPetFriendly={isPetFriendlyTrip}
        layout={layout}
        selectedSeats={selectedSeats}
        lockedSeats={lockedSeats}
        selectSeats={selectSeats}
        onFinishedSelection={this.finishedSeatSelection}
        sendNotification={sendNotification}
        isOpenTicket={isOpenTicket}
        seats={seats}
        isExchange={isExchange}
        passengers={isExchange && passengers}
        busCategories={busCategories}
        minPassengers={minPassengers}
        updateSeat={updateSeat}
        isLoading={loading}
        canGoBack={canGoBack}
        tripPricing={tripPricing}
        passengerTypes={passengerTypes}
        resultsLayout={resultsLayout}
        onClose={onClose}
        hasSeats={hasSeats}
        trip={trip}
        clearError={clearError}
        fetchingDetailsError={fetchingDetailsError}
        firstFloorReclination={firstFloorReclination}
        secondFloorReclination={secondFloorReclination}
        roundTrip={purchase?.roundTrip}
      />
    );
  }
}

DepartureSeats.propTypes = propTypes;

export default DepartureSeats;
