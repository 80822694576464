import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Loader from 'ui/atoms/Loader';
import ExchangeLayout from 'ui/layouts/ExchangeLayout';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import { initGoogleRecaptcha, isReCaptchaLoaded } from 'utils/googleRecaptcha';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import ExchangeSidebar from '../ExchangeSidebar';
import ExchangeFirstStep from '../ExchangeFirstStep';
import ExchangeSecondStep from '../ExchangeSecondStep';

const ExchangeContainer = () => {
  const isLoadingExchange = useSelector((state) => state.exchange.isLoading);
  const env = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();
  const usingRecaptcha = features.EXCHANGE_REQUIRE_RECAPTCHA && env.recaptcha;
  const [isLoadingRecaptcha, setIsLoadingRecaptcha] = useState(!!usingRecaptcha);

  useEffect(() => {
    if (usingRecaptcha) {
      const isLoaded = isReCaptchaLoaded();
      if (!isLoaded) {
        const reCaptchaKey = env.recaptcha.RECAPTCHA_SITE_KEY;
        initGoogleRecaptcha({ reCaptchaKey, onLoad: () => setIsLoadingRecaptcha(false) });
      } else {
        setIsLoadingRecaptcha(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExchangeLayout sidebar={<ExchangeSidebar />}>
      {isLoadingRecaptcha || isLoadingExchange ? (
        <Loader />
      ) : (
        <>
          <Route path="/exchange" exact component={ExchangeFirstStep} />
          <Route path="/exchange/:type" exact component={ExchangeFirstStep} />
          <Route
            path="/exchange/schedule/:operationNumbers/:origin/:destination"
            exact
            component={ExchangeSecondStep}
          />
          <Route
            path="/exchange/schedule/:operationNumbers/:NIT"
            exact
            component={ExchangeSecondStep}
          />
        </>
      )}
    </ExchangeLayout>
  );
};

export default ExchangeContainer;
