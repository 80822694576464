import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, MessageBox, Spacing } from '@reservamos/elements';
import LoyaltyWidget from '../../components/LoyaltyWidget';
import './LoyaltyLink.scss';

const LoyaltyLink = ({ id, visible, hasBox }) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('loyalty');

  if (!features.ALLOW_TO_SAVE_CARDS || !env.siemprePlus?.registerAccount) return null;

  // esconder cuando el usuario este logueado con prop visible
  if (!visible) return null;

  const message = t('register_saving_cards');

  const component = (
    <div className="widget-on-mobile">
      <Spacing alignItems="center" size="S">
        <Icon type="AddCardPrimary" />
        <LoyaltyWidget id={id} variant="link-home" linkText={message} hideIcon visible={visible} />
      </Spacing>
    </div>
  );

  if (hasBox) {
    return <MessageBox borderColor="accent">{component}</MessageBox>;
  }

  return <>{component}</>;
};

LoyaltyLink.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  hasBox: PropTypes.bool,
};

LoyaltyLink.defaultProps = {
  visible: false,
  hasBox: false,
};

export default LoyaltyLink;
