import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Gap, Icon, Spacing, Text, Currency } from '@reservamos/elements';
import './CounterSeat.scss';
import usePricingBeforeCheckout from 'hooks/usePricingBeforeCheckout';
import { getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import useGrowthBookFeatureValue from 'components/GrowthBookProvider/useGrowthBookFeatureValue';

/**
 * It shows the selected seats
 * @param {*} param0 - Props
 * @param {node} param0.children - Children
 * @param {boolean} param0.hasPetFriendly - If selected seats includes a pet friendly seat
 * @returns - CounterSeat component.
 */
const CounterSeat = ({ children, hasPetFriendly, roundTrip }) => {
  const { t } = useTranslation('seats');
  const showSeatPriceMobile = useGrowthBookFeatureValue('seat_price_total_mobile');
  const { purchaseTotal } = usePricingBeforeCheckout({ isRoundTrip: roundTrip });

  return (
    <div className="counter-seat">
      <Spacing fullWidth vertical size="XS">
        <div className="relative flex flex-row justify-between w-full items-center">
          <Gap alignItems="center" columnGap="M">
            <div>
              <Spacing size="S">
                <div className="h-[35px] flex items-center">
                  <Text size="L" weight="bold">
                    {t('pet_counter.title')}
                  </Text>
                </div>
                <Icon type="emojiBusSide" />
              </Spacing>
            </div>

            {children}
          </Gap>
          {showSeatPriceMobile && (
            <div className="flex md:hidden self-start h-[35px] items-center pl-3">
              <Currency
                weight="semibold"
                color="accent"
                size="L"
                price={purchaseTotal}
                sign={getCurrencyPrefix()}
                currency={getCurrencySuffix()}
              />
            </div>
          )}
        </div>

        {hasPetFriendly && (
          <Gap alignItems="center" columnGap="XXS">
            <Icon type="emojiPawsPet" size="S" />
            <Icon type="emojiPerson" size="S" />

            <Text size="S" mobileSize="XS" weight="semibold" color="grayMedium">
              {t('pet_counter.message')}
            </Text>
          </Gap>
        )}
      </Spacing>
    </div>
  );
};

CounterSeat.propTypes = {
  children: PropTypes.node.isRequired,
  hasPetFriendly: PropTypes.bool,
  roundTrip: PropTypes.bool,
};

CounterSeat.defaultProps = {
  hasPetFriendly: false,
};

export default CounterSeat;
