// @ts-check

import AdyenStrategy from '../../methods/card/strategies/AdyenStrategy';
import openPayStrategy from '../../methods/card/strategies/OpenPayStrategy';
import cybersourceStrategy from '../../methods/card/strategies/CybersourceStrategy';
import kushkiStrategy from '../../methods/card/strategies/KushkiStrategy';
import cieloStrategy from '../../methods/card/strategies/CieloStrategy';
import cardMethod from '../../methods/card/Card';

/**
 * @typedef {import('../../methods/card/strategies/CardStrategy').CardStrategy} CardStrategy
 */

const engines = {
  adyen: AdyenStrategy,
  cybersource: cybersourceStrategy,
  kushki: kushkiStrategy,
  cielo: cieloStrategy,
  open_pay: openPayStrategy,
};

/**
 * Factory function for creating Card instances.
 */
function CardFactory() {
  let strategy;

  /**
   * Creates an instance of a Card payment with the given engine.
   */
  function create({ engine }) {
    strategy = engines[engine];
    cardMethod.setStrategy(strategy);
    return cardMethod;
  }

  return { create };
}

const cardFactory = CardFactory();

export default cardFactory;
