import React from 'react';
import PropTypes from 'prop-types';
import { Text, Currency, Badge, Spacing, Icon, Tooltip } from '@reservamos/elements';
import './PricingRow.scss';
import { getCurrencyDecimals, getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';

const PricingRow = ({
  isMain,
  label,
  discountLabel,
  hasDiscount,
  price,
  isNumber,
  icon,
  primary,
  tooltipContent,
  isSpaceBetween,
}) => {
  const fontColor = primary ? 'primary' : 'grayMedium';
  const sign = getCurrencyPrefix();
  return (
    <div className={isSpaceBetween ? 'pricing-flex' : 'pricing-row'}>
      <Spacing vertical size="XS" alignItems="flex-start">
        <Spacing size="XS" alignItems="center">
          <Text
            size="S"
            color={isMain ? 'grayStrong' : fontColor}
            weight={isMain ? 'bold' : 'regular'}
            whiteSpace={tooltipContent ? 'nowrap' : null}
          >
            {`${label} `}
          </Text>

          {tooltipContent && (
            <Tooltip
              type="top"
              variant="light"
              widthBox="286"
              customContent={
                <Text size="XS" color="grayMedium">
                  {tooltipContent}
                </Text>
              }
            >
              <Icon size="S" type="badgeQuestion" />
            </Tooltip>
          )}
        </Spacing>

        {Boolean(discountLabel) && (
          <Badge smallPadding type="success">
            <Text color="white" elementType="span" size="S" weight="semibold">
              {discountLabel}
            </Text>
          </Badge>
        )}
      </Spacing>

      <Spacing alignItems="center" size="XS">
        {icon ? <Icon size="S" type={icon} /> : null}

        {isNumber ? (
          <Text
            size="S"
            color={isMain ? 'grayStrong' : fontColor}
            weight={isMain ? 'bold' : 'regular'}
          >
            {price}
          </Text>
        ) : (
          <Currency
            size="S"
            color={isMain ? 'grayStrong' : 'grayMedium'}
            weight={isMain ? 'bold' : 'regular'}
            price={price}
            currency={getCurrencySuffix()}
            decimals={getCurrencyDecimals()}
            sign={hasDiscount ? `-${sign}` : sign}
          />
        )}
      </Spacing>
    </div>
  );
};

PricingRow.propTypes = {
  label: PropTypes.string.isRequired,
  discountLabel: PropTypes.string,
  price: PropTypes.string.isRequired,
  isMain: PropTypes.bool,
  hasDiscount: PropTypes.bool,
  isNumber: PropTypes.bool,
  icon: PropTypes.string,
  primary: PropTypes.bool,
  tooltipContent: PropTypes.string,
  isSpaceBetween: PropTypes.bool,
};

PricingRow.defaultProps = {
  discountLabel: '',
  isMain: false,
  hasDiscount: false,
  isNumber: false,
  icon: '',
  primary: false,
  tooltipContent: '',
  isSpaceBetween: false,
};

export default PricingRow;
